<div class="titleCard">
  <h1 [ngStyle]="{ color: 233955 }">{{ categoryInfo?.name }}</h1>
</div>
<div class="containerOffers" [ngClass]="{ containerOffersPremium: premium }">
  <app-custom-spinner *ngIf="isLoadingOffers"></app-custom-spinner>
  <div *ngIf="!isLoadingOffers" class="offersCard" [id]="categoryInfo?.id">
    <ng-container *ngIf="offerInCategories.count !== 0; else noOffer">
      <swiper
        [config]="config"
        [grabCursor]="true"
        (swiper)="onSwiper($event)"
        (slideChange)="onSlideChange()"
      >
        <ng-template
          swiperSlide
          *ngFor="let offerData of offerInCategories.data"
        >
          <div
            class="card"
            (click)="$event.preventDefault(); goToDetailOffer(offerData)"
          >
            <div class="iconOfferType">
              <i
                class="fas fa-store icon"
                *ngIf="offerData?.offerType == 'In Store'"
              ></i>
              <i
                class="fas fa-globe icon"
                *ngIf="offerData?.offerType == 'OnLine'"
              ></i>
            </div>
            <img
              [src]="
                offerData.image ? offerData.image : offerData.merchant.image
              "
              class="backimage"
              [ngClass]="{ backimagePremium: premium }"
              [width]="imageWidth"
              [height]="imageHeight"
            />
            <div class="titleCard" [ngClass]="{ titleCardPremium: premium }">
              {{ offerData.title }}
            </div>
          </div>
        </ng-template>
      </swiper>
    </ng-container>
  </div>
</div>
