<div class="tableToolbar">
  <div>
    <div *ngIf="settings.filters" class="filterbar">
      <button (click)="openFilters = !openFilters">
        <i class="pi pi-filter"></i>
      </button>
      <div class="filters" [hidden]="!openFilters">
        <div class="filterRow" *ngFor="let filter of settings.filters">
          <div class="title">{{ filter.display | translate }}</div>
          <div class="dropdown">
            <select (change)="dt.filter($event.target.value, filter.field)">
              <option *ngFor="let option of filter.options" [value]="option">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="settings.search" class="searchbar">
      {{ 'GENERICS.SEARCH' | translate }}
      <input (keyup)="dt.filter($event.target.value, 'search')" />
    </div>
  </div>
</div>

<p-table
  #dt
  [value]="data"
  [columns]="columns"
  [totalRecords]="totalRecords"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [sortField]="settings.defaultSort"
  [rows]="settings.rows || 10"
  [paginator]="settings.paginator"
  responsiveLayout="scroll"
  [showCurrentPageReport]="settings.paginator"
  currentPageReportTemplate="{first}-{last} of {totalRecords}"
>
  <ng-template pTemplate="header" let-columns>
    <tr class="tableHeader">
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.orderable === false ? undefined : col.field"
        [width]="col?.width || ''"
      >
        {{ col.display | translate }}
        <p-sortIcon
          *ngIf="col.orderable !== false"
          [field]="col.field"
        ></p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="!data || !totalRecords"><td [colSpan]="columns.length" class="text-center">
      <span *ngIf="!data">{{ 'DATATABLE.PROCESSING' | translate }}</span>
      <span *ngIf="data && !totalRecords">{{ 'DATATABLE.EMPTY' | translate }}</span>
    </td></tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr
      class="tbody"
      (click)="toggleRow(rowData)"
      [ngClass]="{
        selectedRow: rowData?._isSelected && settings.selectRows,
        selectable: settings.selectRows
      }"
    >
      <td *ngFor="let col of columns">
        <!-- Custom template -->
        <ng-container *ngIf="col?.template">
          <ng-container
            [ngTemplateOutlet]="col.template"
            [ngTemplateOutletContext]="{
              value: rowData[col.field],
              row: rowData,
              field: field
            }"
          >
          </ng-container>
        </ng-container>

        <!-- Default template -->
        <ng-container *ngIf="!col?.template">
          {{ rowData[col.field] }}
        </ng-container>
      </td>

      <td *ngIf="settings.buttons" class="btnbar">
        <button
          *ngIf="settings.buttons?.add"
          (click)="onButtonClick('add', rowData, $event)"
        >
          <i class="pi pi-plus"></i>
        </button>
        <button
          *ngIf="settings.buttons?.edit"
          (click)="onButtonClick('edit', rowData, $event)"
        >
          <i class="pi pi-pencil"></i>
        </button>
        <button
          *ngIf="settings.buttons?.delete"
          (click)="onButtonClick('delete', rowData, $event)"
        >
          <i class="pi pi-trash"></i>
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>