import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss']
})
export class DialogDeleteComponent implements OnInit {
  btnText: string;
  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DialogDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDeleteData
  ) {}

  ngOnInit(): void {
    if (!this.data?.description)
      this.data.description = this.translateService.instant(
        'GENERICS.DELETE_RECORD'
      );

    this.btnText = this.data?.deleteOrInactive
      ? this.translateService.instant('GENERICS.INACTIVERECORD')
      : this.translateService.instant('GENERICS.DELETE');
  }
}

export interface DialogDeleteData {
  title: string;
  description: string;
  deleteOrInactive?: boolean;
}
