<div class="c-changePassword">
  <div class="c-changePassword__card">
    <div mat-dialog-content>
      <div
        class="d-flex flex-row justify-content-between align-items-baseline mb-20px"
      >
        <h2 mat-dialog-title class="text-uppercase p-0 mb-0">
          {{ 'LOGIN.CHANGEPASSWORD' | translate }}
        </h2>
        <div mat-dialog-actions>
          <button
            mat-button
            mat-dialog-close
            class="c-dialog-add-category__button-close"
          >
            <span class="fas fa-times"></span>
          </button>
        </div>
      </div>

      <div id="changePassword">
        <form [formGroup]="formChangePassword">
          <div id="generalForm">
            <div class="mb-4" id="currentPassword">
              <label class="c-changePassword__label">{{
                'LOGIN.CURRENTPASSWORD' | translate
              }}</label>
              <div class="input-group">
                <input
                  formControlName="oldPassword"
                  #passwd1
                  type="password"
                  class="c-changePassword__input form-control form-control-lg"
                  [placeholder]="'REGISTER.PASSWORD' | translate"
                />
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.get('oldPassword').hasError('required') &&
                  (formChangePassword.get('oldPassword').touched ||
                    formChangePassword.get('newPassword').dirty)
                "
              >
                {{ 'REGISTER.ERROR.PASSWORD_REQUIRED' | translate }}
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.get('oldPassword').hasError('minlength') &&
                  formChangePassword.get('oldPassword').touched
                "
              >
                {{ 'REGISTER.ERROR.PASSWORD_MIN_CHARACTERS' | translate }}
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.get('oldPassword').hasError('maxlength') &&
                  formChangePassword.get('oldPassword').touched
                "
              >
                {{ 'REGISTER.ERROR.PASSWORD_MAX_CHARACTERS' | translate }}
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.get('oldPassword').hasError('pattern') &&
                  formChangePassword.get('oldPassword').touched
                "
              >
                {{ 'REGISTER.ERROR.PATTERN' | translate }}
              </div>
            </div>
            <div class="mb-3" id="newPassword">
              <label class="c-changePassword__label">{{
                'LOGIN.NEWPASSWORD' | translate
              }}</label>
              <div class="input-group">
                <input
                  formControlName="newPassword"
                  #passwd2
                  type="password"
                  class="c-changePassword__input form-control form-control-lg"
                  [placeholder]="'REGISTER.REPEAT' | translate"
                />
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.get('newPassword').hasError('required') &&
                  (formChangePassword.get('oldPassword').dirty ||
                    formChangePassword.get('newPassword').touched)
                "
              >
                {{ 'REGISTER.ERROR.PASSWORD_REQUIRED' | translate }}
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.get('newPassword').hasError('minlength') &&
                  formChangePassword.get('newPassword').touched
                "
              >
                {{ 'REGISTER.ERROR.PASSWORD_MIN_CHARACTERS' | translate }}
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.get('newPassword').hasError('maxlength') &&
                  formChangePassword.get('newPassword').touched
                "
              >
                {{ 'REGISTER.ERROR.PASSWORD_MAX_CHARACTERS' | translate }}
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.get('newPassword').hasError('pattern') &&
                  formChangePassword.get('newPassword').touched
                "
              >
                {{ 'REGISTER.ERROR.PATTERN' | translate }}
              </div>
              <div
                class="c-changePassword__error"
                *ngIf="
                  formChangePassword.hasError('itSame') &&
                  formChangePassword.get('oldPassword').dirty &&
                  formChangePassword.get('newPassword').dirty
                "
              >
                {{ 'REGISTER.ERROR.PASSWORD_IT_SAME' | translate }}
              </div>
            </div>
          </div>
          <div class="c-changePassword__listButtons mt-5">
            <button
              class="btn btn-primary--active"
              type="button"
              [disabled]="!formChangePassword.valid"
              (click)="changePassword()"
            >
              {{ 'GENERICS.SAVE' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
