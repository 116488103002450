import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CreateUpdateImage } from '../models/image.model';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private api: ApiService) {}

  create(uri: string, payload: CreateUpdateImage) {
    return this.api.post(uri, payload);
  }

  update(uri: string, payload: CreateUpdateImage) {
    return this.api.put(uri, payload);
  }

  delete(uri: string) {
    return this.api.delete(uri);
  }
}
