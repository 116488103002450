<div class="dropdown">
  <button
    class="btn dropdown-toggle"
    type="button"
    id="dropdownLanguage"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <img
      width="25"
      height="14"
      [src]="'assets/img/flags/' + selectedLang.key + '.png'"
      [alt]="selectedLang.name"
    />
    <span class="font-bold">{{ selectedLang.name }}</span>
  </button>
  <ul class="dropdown-menu">
    <li *ngFor="let language of appLanguages">
      <div
        class="d-flex flex-row justify-content-space-around m-0"
        *ngIf="language != selectedLang"
      >
        <a class="dropdown-item p-2" (click)="changeLang(language)">
          <img
            width="25"
            height="14"
            [src]="'assets/img/flags/' + language.key + '.png'"
            [alt]="language.name"
          />
          <span>{{ language.name }}</span>
        </a>
        <button
          *ngIf="language.key != default && !readMode"
          class="me-2 trash-icon"
          (click)="removeLanguage(language)"
        >
          <span class="fas fa-trash"></span>
        </button>
      </div>
    </li>
    <li *ngIf="addLanguage">
      <a class="dropdown-item add-lang" (click)="addLang()">{{
        'GENERICS.ADDLANGUAGE' | translate
      }}</a>
    </li>
  </ul>
</div>
