import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeUrl,
  SafeScript,
  SafeStyle
} from '@angular/platform-browser';

@Pipe({
  name: 'sanitize'
})
export class SanitizePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    value: any,
    args?: any
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (args) {
      switch (args) {
        case 'HTML':
          return this.sanitizer.bypassSecurityTrustHtml(value);
        case 'STYLE':
          return this.sanitizer.bypassSecurityTrustStyle(`url(${value})`);
        case 'SCRIPT':
          return this.sanitizer.bypassSecurityTrustScript(value);
        case 'URL':
          return this.sanitizer.bypassSecurityTrustUrl(value);
        case 'RESOURCE':
          return this.sanitizer.bypassSecurityTrustResourceUrl(`url(${value})`);
        default:
          throw new Error(`Invalid safe type specified: ${args}`);
      }
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(value);
    }
  }
}
