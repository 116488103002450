import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { BranchDetail } from 'src/app/dashboard/models/branch.model';

@Component({
  selector: 'app-custom-map',
  templateUrl: './custom-map.component.html',
  styleUrls: ['./custom-map.component.scss']
})
export class CustomMapComponent implements OnInit {
  @Input() selectedMapPoint: BranchDetail;
  @Input() addressList: BranchDetail[];
  @Input() mapWidth: BranchDetail[];
  @Input() mapHeight: BranchDetail[];
  @Input() homePage: BranchDetail;

  @Output() selectedBranch = new EventEmitter<any>();
  public iconMap = {
    url: '/assets/img/icon-location-map.svg',
    scaledSize: { height: 30, width: 30 }
  };
  previous;
  desactiveControls = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.desactiveControls = event.target.innerWidth <= 414 ? true : false;
  }

  constructor() {}

  ngOnInit(): void {
    if (window.innerWidth <= 414) {
      this.desactiveControls = true;
    }
  }

  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  protected selectMarkerBranch(infoWindow, gm, marker) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();

    this.selectedBranch.emit(marker);
  }
}
