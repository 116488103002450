import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Language } from 'src/app/dashboard/models/language.model';

@Component({
  selector: 'app-dialog-add-language',
  templateUrl: './dialog-add-language.component.html',
  styleUrls: ['./dialog-add-language.component.scss']
})
export class DialogAddLanguageComponent implements OnInit {
  selectedLanguage: Language;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {}

  languageSelectChangedHandler(selectLanguage: Language) {
    this.selectedLanguage = selectLanguage;
  }
}
