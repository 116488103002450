<div class="c-dialog-delete">
  <div class="d-flex flex-column align-items-center mb-3 mt-3">
    <span class="fas fa-exclamation-triangle fa-2x mb-0"></span>
    <h2 class="text-capitalize mb-3 mt-2" mat-dialog-title>
      {{ btnText }} {{ this.data.title | translate }}
    </h2>
    <div class="text-center">{{ this.data.description | translate }}</div>
  </div>

  <div class="hr mb-3"></div>

  <div class="row d-sm-flex justify-content-sm-center" mat-dialog-actions>
    <button
      class="mb-2 mb-sm-0 mx-sm-3 btn btn-secondary--active"
      mat-button
      mat-dialog-close
    >
      {{ 'GENERICS.CANCEL' | translate }}
    </button>
    <button
      class="mx-sm-3 btn btn-danger--active"
      mat-button
      [mat-dialog-close]="true"
    >
      {{ btnText }}
    </button>
  </div>
</div>
