import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguagesService } from 'src/app/dashboard/services/languages.service';
import { DialogAddLanguageComponent } from '../dialogs/dialog-add-language/dialog-add-language.component';
import { environment } from './../../../../../environments/environment';
import { Language } from './../../../models/language.model';
// import { DialogAddLanguageComponent } from './../dialogs/dialog-add-language/dialog-add-language.component';

@Component({
  selector: 'app-dropdown-languages',
  templateUrl: './dropdown-languages.component.html',
  styleUrls: ['./dropdown-languages.component.scss']
})
export class DropdownLanguagesComponent implements OnInit {
  @Input() addLanguage = true;
  @Input() readMode = false;
  @Input() availableTranslations: Language[] = [];
  @Output() changeLanguage = new EventEmitter<any>();
  @Output() deleteLanguage = new EventEmitter<Language>();
  appLanguages: Language[];
  selectedLang: Language = environment.defaultLanguage;

  default = environment.defaultLanguage.key;

  constructor(public dialog: MatDialog, public languages: LanguagesService) {
    this.appLanguages = [];
    this.selectedLang = environment.defaultLanguage;
  }

  ngOnInit(): void {
    if (this.availableTranslations.length > 0) {
      this.appLanguages = this.availableTranslations;
      this.selectedLang = this.availableTranslations.find(
        (lang: Language) => lang.id === environment.defaultLanguage.id
      );
    } else {
      this.appLanguages.push(environment.defaultLanguage);
    }
  }

  changeLang(language: Language) {
    const oldLanguage = this.selectedLang;
    this.selectedLang = language;
    this.changeLanguage.emit({ oldLanguage, language });
  }

  removeLanguage(language: Language) {
    const langLength = this.getLanguageLength();
    this.appLanguages = this.appLanguages.filter(
      (lang) => lang.iso !== language.iso
    );
    this.addLanguage = this.appLanguages.length >= langLength ? false : true;
    this.deleteLanguage.emit(language);
  }

  addLang() {
    const langLength = this.getLanguageLength();
    const data: any = this.appLanguages;
    const dialogRef = this.dialog.open(DialogAddLanguageComponent, { data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        !this.appLanguages.some((x) => x.iso === result.iso)
          ? this.appLanguages.push(result)
          : '';
        this.changeLang(result);
        this.addLanguage =
          this.appLanguages.length >= langLength ? false : true;
      }
    });
  }

  getLanguageLength(): number {
    let length = 0;
    this.languages.getPaginated().subscribe((langs: any) => {
      length = langs.count;
    });
    return length;
  }
}
