import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OfferDetail } from '../models/offer.model';
import { PaginatedResponse } from '../models/paginated.model';
import { TagDetails } from '../models/tag.model';
import { default as clientsConfigurations } from './../../../style/clientsCss/clients-configurations.json';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class OfferService {
  private readonly uri = 'offer';
  private clientConfiguration = clientsConfigurations;

  constructor(private apiService: ApiService) {}

  getPaginated(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false
  ): Observable<PaginatedResponse> {
    const params = this.apiService.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    return this.apiService.get(this.uri, params);
  }
  getOffersPremium(
    currentPage = 0,
    pageSize = 10,
    sortBy = 'weight',
    sortDesc = false,
    filterBy?: ''
  ): Observable<PaginatedResponse> {
    const params = this.apiService.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    const url = filterBy
      ? `${this.uri}/premium?filter=${filterBy}`
      : `${this.uri}/premium`;
    return this.apiService.get<PaginatedResponse>(url, params);
  }
  getOffersWithHeroBanner(
    currentPage = 0,
    pageSize = 10,
    sortBy = '',
    sortDesc = false,
    filterBy?: ''
  ): Observable<PaginatedResponse> {
    const params = this.apiService.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    const url = filterBy
      ? `${this.uri}/hero?filter=${filterBy}`
      : `${this.uri}/hero`;
    return this.apiService.get<PaginatedResponse>(url, params);
  }

  getCategoriesByOffer(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false,
    id: number
  ) {
    const params = this.apiService.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    return this.apiService.get<any>(`${this.uri}/${id}/category`, params);
  }

  getOffer(id: number): Observable<OfferDetail> {
    return this.apiService.get(`${this.uri}/${id}`);
  }

  getTagsByOffer(id: number): Observable<TagDetails> {
    return this.apiService.get<any>(`${this.uri}/${id}/tag`);
  }

  getBranchesByOffer(id: number): Observable<PaginatedResponse> {
    return this.apiService.get<any>(`${this.uri}/${id}/branch`);
  }
  getOfferRedeem(id: number): Observable<any> {
    return this.apiService.post<any>(`${this.uri}/${id}/redeem`, {});
  }
  getRelatedOffers(id: number, size = 5): Observable<OfferDetail[]> {
    return this.apiService.get<any>(`${this.uri}/${id}/related?size=${size}`);
  }

  getNearrestOffer(
    currentPage = 0,
    pageSize = 20,
    sortBy: string = null,
    sortDesc = false
  ): Observable<any> {
    const params = this.apiService.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    return this.apiService.get<any>(`${this.uri}/nearest`, params);
  }

  postEmailForLater(
    offerCode: string,
    offerId: number,
    userEmail: string,
    clientCss: string = localStorage.getItem('client')
  ): Observable<any> {
    const clientInfo = this.clientConfiguration.find((object) => {
      return object.clientName === clientCss;
    });
    const clientLogo = clientInfo.clientLogoUrl;
    const clientPrimaryColor = clientInfo.primaryColor;
    const clientSecundaryColor = clientInfo.secondaryColor;
    return this.apiService.post<any>(`${this.uri}/${offerId}/emailcode`, {
      code: offerCode,
      email: userEmail,
      logo: clientLogo,
      primaryColor: clientPrimaryColor,
      secondaryColor: clientSecundaryColor
    });
  }

  postVoteForOffer(
    id: number,
    vote: boolean,
    branchId?: number
  ): Observable<any> {
    return this.apiService.post<any>(`${this.uri}/${id}/vote`, {
      like: vote,
      branchId: branchId
    });
  }
  postCommentForKoOffer(id: number, voteBody: any): Observable<any> {
    return this.apiService.post<any>(`${this.uri}/${id}/comment`, {
      comment: voteBody.comment,
      voteId: voteBody.id
    });
  }
}
