<div class="youps-notification-panel" [ngClass]="{ 'show-notification-panel': showPanel }" >
    <div class="youps-notification-logo" (click)="goToOffer()">
        <img  *ngIf="notification?.data" class="pre_notification-logo" [src]="notification?.data.merchantImage" [alt]="notification?.data.merchant">
    </div>
    <div class="youps-notification-panel-content" (click)="goToOffer()">
        <div class="youps-notification-title">{{ notification?.title }}</div>
        <div class="youps-notification-body">{{ notification?.body }}</div>
        <div *ngIf="notification?.data" class="youps-notification-body">{{ notification?.data.title }}</div>
    </div>
    <div class="youps-notification-close" (click)="dismissNotification()">
        <span class="material-icons">close</span>
    </div>
</div>