import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BranchDetail } from 'src/app/dashboard/models/branch.model';
import { OfferDetail } from 'src/app/dashboard/models/offer.model';
import { OfferService } from 'src/app/dashboard/services/offer.service';

@Component({
  selector: 'app-dialog-home-map',
  templateUrl: './dialog-home-map.component.html',
  styleUrls: ['./dialog-home-map.component.scss']
})
export class DialogHomeMapComponent implements OnInit {
  public _mapWidth = '85vw';
  public _mapHeigth = '38vw';
  public defaultCoords = {
    location: {
      latitude: 40.416775,
      longitude: -3.70379,
      zoom: 14
    }
  };
  public markers: BranchDetail[] = [];

  latitude: number;
  longitude: number;
  zoom: number;
  selectedAddress: any;
  responsive = false;
  urlOffer: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._mapWidth = event.target.innerWidth <= 414 ? '79vw' : '85vw';
    this._mapHeigth = event.target.innerWidth <= 414 ? '93vw' : '114vw';
    this._mapWidth = event.target.innerWidth <= 480 ? '79vw' : '85vw';
    this._mapHeigth = event.target.innerWidth <= 480 ? '123vw' : '114vw';
    this._mapHeigth = event.target.innerWidth <= 360 ? '106vw' : '114vw';
    this._mapHeigth = event.target.innerWidth <= 320 ? '80vw' : '114vw';
    this.responsive = event.target.innerWidth <= 414 ? true : false;
  }

  constructor(
    public dialogRef: MatDialogRef<DialogHomeMapComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private offerService: OfferService
  ) {}

  ngOnInit(): void {
    if (window.innerWidth <= 414) {
      this._mapWidth = '79vw';
      this._mapHeigth = '93vw';
      this.responsive = true;
    }
    if (window.innerWidth <= 480) {
      this._mapWidth = '79vw';
      this._mapHeigth = '123vw';
      this.responsive = true;
    }
    if (window.innerWidth <= 360) {
      this._mapWidth = '79vw';
      this._mapHeigth = '106vw';
      this.responsive = true;
    }
    if (window.innerWidth <= 320) {
      this._mapWidth = '79vw';
      this._mapHeigth = '80vw';
      this.responsive = true;
    }
    this.setCurrentLocation();
    this.getNearBranchs();
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.defaultCoords = {
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            zoom: 8
          }
        };
      });
    }
  }

  getNearBranchs() {
    this.offerService.getNearrestOffer().subscribe((result) => {
      result.data.forEach(async (element: OfferDetail) => {
        const branchAssociated: BranchDetail = element.branch[0];
        branchAssociated.distanceKm =
          Math.round((element.branch[0].distanceKm + Number.EPSILON) * 100) /
          100;
        branchAssociated.offer = element;
        branchAssociated.merchant = element.merchant;
        branchAssociated.internalUrl = await this.buildOfferUrl(
          element.id,
          element.merchant.tradingName,
          element.title
        );
        branchAssociated.mapPing = {
          icon: element.merchant.image,
          scaledSize: {
            height: 70,
            width: 70
          }
        };
        this.markers.push(branchAssociated);
      });
    });
  }

  async buildOfferUrl(
    offerId: Number,
    merchantTitle: any,
    offerTitle: any
  ): Promise<string> {
    let offerUrl = '';
    const titleMerchant = merchantTitle
      .replaceAll(' ', '-')
      .replaceAll('%', '');
    const titleOffer = offerTitle.replaceAll(' ', '-').replaceAll('%', '');

    offerUrl =
      '/webcliente/offer/' + offerId + '-' + titleMerchant + '-' + titleOffer;
    return offerUrl;
  }

  getSelectedBranch(branch) {
    this.selectedAddress = branch;
    const offer = this.markers.find(
      (marker) => marker.id === this.selectedAddress.id
    );
    this.urlOffer = offer.offer.affiliateLink;
  }
}
