import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertType } from '../../../../components/alert/alert.component';
import {
  DatatableColumns,
  DatatableSettings
} from '../../../../models/datatable.model';
import { ServiceService } from '../../../../services/service.service';
import { UtilService } from '../../../../services/util.service';
import { WorkerService } from '../../../../services/worker.service';
import {
  DialogDeleteComponent,
  DialogDeleteData
} from '../../dialogs/dialog-delete/dialog-delete.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';

@Component({
  selector: 'app-datatable-service-list',
  templateUrl: './datatable-service-list.component.html',
  styleUrls: ['./datatable-service-list.component.scss']
})
export class DatatableServiceListComponent implements OnInit {
  public data: any;
  public totalRecords: number;
  public cols: DatatableColumns[];
  public settings: DatatableSettings;

  _tooltipComponent = TooltipComponent;

  constructor(
    private workerService: WorkerService,
    private serviceService: ServiceService,
    private utilService: UtilService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.cols = [
      { display: 'GENERICS.IMAGE', field: 'image' },
      { display: 'SERVICE.SERVICE', field: 'title' },
      { display: 'SERVICE.PRICE', field: 'price' },
      { display: 'GENERICS.LANGUAGE', field: 'availableLanguage' },
      { display: 'SERVICE.STATUS', field: 'active' }
    ];

    this.settings = {
      rows: 10,
      search: true,
      paginator: true,
      defaultSort: 'image',
      filters: [
        {
          display: 'SERVICE.STATUS',
          field: 'status',
          options: ['All', 'Active', 'Inactive']
        }
      ],
      buttons: {
        delete: true
      }
    };
  }

  async loadData(event) {
    const response = await this.workerService
      .getWorkerServicePaginated(
        event?.pageSize,
        event?.currentPage,
        event?.orderBy,
        event?.desc,
        event?.filters?.search?.value,
        event?.filters?.status?.value
      )
      .toPromise();

    this.totalRecords = response.count;
    this.data = response.data;
  }

  async deleteService(event) {
    if (event.action == 'delete') {
      const data: DialogDeleteData = {
        title: 'SERVICE.SERVICE',
        description: ''
      };

      const dialogRef = this.dialog.open(DialogDeleteComponent, { data });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.serviceService.delete(event.row.id).subscribe(
            () => window.location.reload(),
            (error) => {
              this.utilService.alert(
                'GENERICS.ERROR.SERVER_ERROR',
                AlertType.danger
              );
            }
          );
        }
      });
    }
  }
}
