<app-custom-spinner *ngIf="isLoadingHeroBanners"></app-custom-spinner>
<ngb-carousel *ngIf="!isLoadingHeroBanners">
  <ng-template ngbSlide *ngFor="let image of heroBanneOffers.data">
    <div
      class="wrapper heroDiv"
      (click)="$event.preventDefault(); goToDetailOffer(image)"
    >
      <img
        [src]="image.image"
        [alt]="image.title"
        [width]="imageWidth"
        [height]="imageHeight"
      />
    </div>
  </ng-template>
</ngb-carousel>
