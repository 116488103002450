<div class="c-dialog-add-price">
  <div mat-dialog-content>
    <div
      class="d-flex flex-row justify-content-between align-items-baseline mb-20px"
    >
      <h2 mat-dialog-title class="text-uppercase p-0 mb-0">
        {{ 'SERVICE.NEWPRICE' | translate }}
      </h2>
      <div mat-dialog-actions>
        <button
          mat-button
          mat-dialog-close
          class="c-dialog-add-price__button-close"
        >
          <span class="fas fa-times"></span>
        </button>
      </div>
    </div>
    <div>
      <form [formGroup]="formPriceDuration">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <h3 class="mb-10px">
              {{ 'SERVICE.PRICE' | translate }} ({{ currency }})
            </h3>
            <div class="col-4 m-2">
              <mat-form-field class="w-100">
                <input
                  step="0.01"
                  #newPrice
                  matInput
                  formControlName="price"
                  class="c-dialog-add-price__input"
                  type="number"
                  placeholder="00.00"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 row">
            <h3 class="mb-10px">{{ 'SERVICE.DURATION' | translate }}</h3>
            <div id="hour" class="col-4 m-2">
              <h3 class="mb-10px">{{ 'GENERICS.HOURS' | translate }}</h3>
              <mat-form-field class="w-100">
                <input
                  #newDuration
                  matInput
                  formControlName="hours"
                  class="c-dialog-add-price__input"
                  type="number"
                  placeholder="{{ 'SERVICE.DURATION' | translate }}"
                />
              </mat-form-field>
            </div>
            <div id="minutes" class="col-4 m-2">
              <h3 class="mb-10px">{{ 'GENERICS.MINUTES' | translate }}</h3>
              <mat-form-field class="w-100">
                <input
                  #newDuration
                  matInput
                  formControlName="minutes"
                  class="c-dialog-add-price__input"
                  type="number"
                  placeholder="{{ 'SERVICE.DURATION' | translate }}"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div mat-dialog-actions class="c-dialog-add-price__actions-bottom">
      <button
        class="btn btn-primary--active"
        mat-button
        [mat-dialog-close]="formPriceDuration.value"
        [disabled]="!formPriceDuration.valid"
      >
        {{ 'GENERICS.SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
