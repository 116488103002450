import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Language } from 'src/app/dashboard/models/language.model';
import {
  DatatableColumns,
  DatatableSettings
} from '../../../../models/datatable.model';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { LanguagesService } from './../../../../services/languages.service';

@Component({
  selector: 'app-datatable-language',
  templateUrl: './datatable-language.component.html',
  styleUrls: ['./datatable-language.component.scss']
})
export class DatatableLanguageComponent implements OnInit {
  @Input() languageJustSelected: Language[];
  public data: any;
  public totalRecords: number;
  public cols: DatatableColumns[];
  public settings: DatatableSettings;

  _tooltipComponent = TooltipComponent;
  selectedLanguage: Language;

  @Output() languageChanged: EventEmitter<Language> = new EventEmitter();

  constructor(public languages: LanguagesService) {}

  ngOnInit(): void {
    this.cols = [
      { display: '', field: 'flag' },
      { display: 'GENERICS.LANGUAGE', field: 'name' }
    ];

    this.settings = {
      rows: 10,
      search: true,
      paginator: true,
      selectRows: 'single',
      defaultSort: 'image'
    };
  }

  async loadData(event) {
    await this.languages
      .getPaginated(
        event?.pageSize,
        event?.currentPage,
        event?.orderBy,
        event?.desc
      )
      .subscribe((langs: any) => {
        console.log(langs);
        const options = [];
        if (this.languageJustSelected) {
          langs.data.filter((d) => {
            this.languageJustSelected.filter((s) => {
              if (d.key === s.key) {
                options.push(d);
              }
            });
          });
        }

        this.totalRecords = langs.count;
        this.data = langs.data.filter((d) => !options.includes(d));
      });
  }
  rowSelected(selectLanguage: Language) {
    this.selectedLanguage = selectLanguage;
    this.languageChanged.emit(this.selectedLanguage);
  }
}
