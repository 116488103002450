// Angular modules
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// External Modules
import { TranslateModule } from '@ngx-translate/core';
import { AgmOverlays } from 'agm-overlays';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPaginationModule } from 'ngx-pagination';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
//swiperjs
import { SwiperModule } from 'swiper/angular';
// Components
import { CardComponent } from './components/card/card.component';
import { HeroBannerCarouselComponent } from './components/carousell/hero-banner-carousel/hero-banner-carousel.component';
import { OffersCarouselComponent } from './components/carousell/offers-carousel/offers-carousel.component';
import { ServiceCarouselComponent } from './components/carousell/service-carrousel/service-carousel.component';
import { CustomDatatableComponent } from './components/customs/custom-datatable/custom-datatable.component';
import { CustomMapComponent } from './components/customs/custom-map/custom-map.component';
import { CustomSimpleMapComponent } from './components/customs/custom-simple-map/custom-simple-map.component';
import { CustomSpinnerModule } from './components/customs/custom-spinner/custom-spinner.module';
import { DatatableLanguageComponent } from './components/datatable/datatable-languages/datatable-language.component';
import { DatatableServiceListComponent } from './components/datatable/datatable-service-list/datatable-service-list.component';
import { DatatableWorkerLastBookingComponent } from './components/datatable/datatable-worker-last-booking/datatable-worker-last-booking.component';
import { DatatableWorkerPaymentsComponent } from './components/datatable/datatable-worker-payments/datatable-worker-payments.component';
import { DatatableWorkerServiceLocationComponent } from './components/datatable/datatable-worker-service-location/datatable-worker-service-location.component';
import { DialogAddLanguageComponent } from './components/dialogs/dialog-add-language/dialog-add-language.component';
import { DialogBranchComponent } from './components/dialogs/dialog-branch/dialog-branch.component';
import { DialogChangePasswordsComponent } from './components/dialogs/dialog-change-password/dialog-change-password.component';
import { DialogCodeComponent } from './components/dialogs/dialog-code/dialog-code.component';
import { DialogDeleteComponent } from './components/dialogs/dialog-delete/dialog-delete.component';
import { DialogHomeMapComponent } from './components/dialogs/dialog-home-map/dialog-home-map.component';
import { DialogLogInComponent } from './components/dialogs/dialog-logIn/dialog-logIn.component';
import { DialogManagerServiceCalendarTimesComponent } from './components/dialogs/dialog-manage-service-calendar-times/dialog-manage-service-calendar-times.component';
import { DialogManagerServiceExceptionsComponent } from './components/dialogs/dialog-manage-service-exceptions/dialog-manage-service-exceptions.component';
import { DialogManagementPriceComponent } from './components/dialogs/dialog-management-prices/dialog-management-prices.component';
import { DropdownLanguagesComponent } from './components/dropdown-languages/dropdown-languages.component';
import { FormLogInSharedComponent } from './components/forms/form-logIn/form-logIn-shared.component';
import { PushNotificationComponent } from './components/push-notification/push-notification.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
// Directives
import { CustomTooltipDirective } from './directives/custom-tooltip.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { TableColumnDirective } from './directives/table-column.directive';
import { MaterialModule } from './material.module';
// Pipes
import { DynamicPipe } from './pipes/dynamic.pipe';
import { KebabCasePipe } from './pipes/kebab-case.pipe';
import { SanitizePipe } from './pipes/sanitize.pipe';

const _modules = [
  CommonModule,
  AngularEditorModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  NgbModule,
  TranslateModule,
  MaterialModule,
  QRCodeModule,
  AgmCoreModule,
  AgmOverlays,
  NgxPaginationModule,
  CustomSpinnerModule,
  SwiperModule,
  TableModule,
  DropdownModule,
  AgmJsMarkerClustererModule
];

const _declarations = [
  UploadImageComponent,
  DialogManagerServiceCalendarTimesComponent,
  DialogManagerServiceExceptionsComponent,
  TableColumnDirective,
  DynamicPipe,
  DragAndDropDirective,
  SanitizePipe,
  KebabCasePipe,
  CardComponent,
  DialogCodeComponent,
  OffersCarouselComponent,
  ServiceCarouselComponent,
  HeroBannerCarouselComponent,
  PushNotificationComponent,
  DropdownLanguagesComponent,
  CustomMapComponent,
  DialogBranchComponent,
  DialogHomeMapComponent,
  CustomDatatableComponent,
  DatatableWorkerPaymentsComponent,
  DatatableServiceListComponent,
  DatatableLanguageComponent,
  TooltipComponent,
  DialogDeleteComponent,
  DialogManagementPriceComponent,
  DialogChangePasswordsComponent,
  CustomTooltipDirective,
  DialogLogInComponent,
  FormLogInSharedComponent,
  DialogAddLanguageComponent,
  CustomSimpleMapComponent,
  DatatableWorkerServiceLocationComponent,
  DatatableWorkerLastBookingComponent
];

@NgModule({
  declarations: [..._declarations],
  imports: [..._modules],
  exports: [..._declarations, ..._modules]
})
export class SharedModule {}
