import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alert, AlertType } from '../components/alert/alert.component';
import { AppEvents, EventBusService } from './event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(private bus: EventBusService) {}

  alert(message: string, type: AlertType = AlertType.success, delay?: number) {
    const alert: Alert = { message, type, delay };
    this.bus.emit(AppEvents.alert, alert);
  }
  toKebabCase(value: string) {
    if (!value) return;

    return value
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-');
  }

  buildPaginatedHttpParams(
    currentPage: number,
    pageSize: number,
    sortBy: string,
    sortDesc: boolean,
    search: string = null,
    filter: string = null,
    appkey: string = null
  ): HttpParams {
    let params = new HttpParams();
    if (currentPage != undefined || currentPage != null)
      params = params.append('currentPage', currentPage.toString());
    if (pageSize) params = params.append('pageSize', pageSize.toString());
    if (sortBy) params = params.append('orderBy', sortBy);
    if (sortDesc != undefined || sortDesc != null)
      params = params.append('desc', sortDesc.toString());
    if (search) params = params.append('search', search);
    if (filter) params = params.append('filter', filter);
    if (appkey) params = params.append('appkey', appkey);
    return params;
  }
  cathPasteEventAndPutwithoutStyle(htmlElementId: string) {
    document
      .getElementById(htmlElementId)
      .addEventListener('paste', function (e: ClipboardEvent) {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        document.execCommand('insertHTML', false, text);
      });
  }
  dateToString(date: Date): string {
    return `${date.getFullYear()}-${this.zeroPad(
      date.getMonth() + 1,
      2
    )}-${this.zeroPad(date.getDate(), 2)}`;
  }

  zeroPad = (num, places) => String(num).padStart(places, '0');
  //#region priceDurationService
  convertIntoDuration(values) {
    values.forEach((element) => {
      let minutes = Math.floor((element.duration / (1000 * 60)) % 60);
      let hours = Math.floor((element.duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? 0 + hours : hours;
      minutes = minutes < 10 ? 0 + minutes : minutes;

      element.duration = {
        hours: hours,
        minutes: minutes
      };
    });
    return values;
  }
  convertIntoMiliseconds(values) {
    values.forEach((element) => {
      if (typeof element.duration != 'number') {
        const hourToMill = parseInt(element.duration.hours) * 3600000;
        const minToMill = parseInt(element.duration.minutes) * 60000;
        element.duration = hourToMill + minToMill;
      }
      element.price = element.price.toString();
    });
    return values;
  }
  //#endregion
}
