import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateUpdateImage } from '../models/image.model';
import { PaginatedResponse } from '../models/paginated.model';
import { ImageService } from '../services/image.service';
import { LocationService } from '../services/location.service';
import { PaymentService } from '../services/payment.service';
import {
  ServiceDetail,
  ServiceLocation,
  ServiceTimeSlot,
  ServiceTranslation
} from './../../dashboard/models/service.model';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  private readonly uri = 'service';

  constructor(
    private apiService: ApiService,
    private paymentService: PaymentService,
    private imageService: ImageService,
    private locationService: LocationService
  ) {}

  getServices(
    groupFilter: number,
    typeFilter: number
  ): Observable<PaginatedResponse> {
    let params = new HttpParams();
    if (groupFilter) params = params.append('groupId', groupFilter.toString());
    if (typeFilter) params = params.append('typeId', typeFilter.toString());
    return this.apiService.get(this.uri, params);
  }

  buyService(
    guestBuyData,
    id: number,
    priceId: number,
    quantity: number
  ): Observable<any> {
    return this.paymentService.startPayment(
      guestBuyData.customerName,
      guestBuyData.customerEmail,
      guestBuyData.address,
      guestBuyData.locality,
      guestBuyData.postalCode,
      [{ id, priceId, quantity: quantity }]
    );
  }

  getServiceById(id: number): Observable<ServiceDetail> {
    return this.apiService.get(`${this.uri}/${id}`);
  }
  getLocationServiceById(id: number): Observable<PaginatedResponse> {
    return this.apiService.get(`${this.uri}/${id}/location`);
  }
  getTimeSlotServiceById(
    id: number,
    dateFrom: string
  ): Observable<ServiceTimeSlot> {
    return this.apiService.get(
      `${this.uri}/${id}/timeSlot?from=${dateFrom}&to=2022-12-31`
    );
  }

  create(data) {
    return this.apiService.post(this.uri, data);
  }

  update(id: number, data) {
    return this.apiService.put(`${this.uri}/${id}`, data);
  }

  delete(id: number) {
    return this.apiService.delete(`${this.uri}/${id}`);
  }

  createImage(id: number, base64: string, type: string, main: boolean) {
    const requestCreateImage: CreateUpdateImage = new CreateUpdateImage(
      id,
      'removeFilename',
      base64,
      type,
      main
    );
    return this.imageService.create(`${this.uri}/image`, requestCreateImage);
  }
  updateImage(id: number, base64: string, type: string, main: boolean) {
    const requestUpdateImage: CreateUpdateImage = new CreateUpdateImage(
      null,
      'updateFilename',
      base64,
      type,
      main
    );
    return this.imageService.update(
      `${this.uri}/image/${id}`,
      requestUpdateImage
    );
  }
  deleteImage(id: number) {
    return this.imageService.delete(`${this.uri}/image/${id}`);
  }

  createLocations(createLocationData: ServiceLocation) {
    return this.locationService.create(createLocationData);
  }
  updateLocations(locationId, updateLocationData: ServiceLocation) {
    return this.locationService.update(updateLocationData, locationId);
  }
  deleteLocation(locationId) {
    return this.locationService.delete(locationId);
  }

  createPrice(priceData: any) {
    return this.apiService.post(`${this.uri}/price`, priceData);
  }
  updatePrice(priceId, priceData: any) {
    return this.apiService.put(`${this.uri}/price/${priceId}`, priceData);
  }
  deletePrice(priceId) {
    return this.apiService.delete(`${this.uri}/price/${priceId}`);
  }

  getAvailability(serviceId: number) {
    return this.apiService.get(`${this.uri}/${serviceId}/availability`);
  }
  createAvailability(availabilityData: any) {
    return this.apiService.post(`${this.uri}/availability`, availabilityData);
  }
  updateAvailability(id: number, availabilityData: any) {
    return this.apiService.put(
      `${this.uri}/availability/${id}`,
      availabilityData
    );
  }
  deleteAvailability(id: number) {
    return this.apiService.delete(`${this.uri}/availability/${id}`);
  }

  getServiceException(serviceId: number) {
    return this.apiService.get(`${this.uri}/${serviceId}/exception`);
  }
  createServiceException(exceptionData: any) {
    return this.apiService.post(`${this.uri}/exception`, exceptionData);
  }
  updateServiceException(id: number, exceptionData: any) {
    return this.apiService.put(`${this.uri}/exception/${id}`, exceptionData);
  }
  deleteServiceException(id: number) {
    return this.apiService.delete(`${this.uri}/exception/${id}`);
  }

  sendTranslations(serviceId: number, translationsData: ServiceTranslation) {
    const data = {
      service: {
        id: serviceId
      },
      language: translationsData.language,
      title: translationsData.title,
      description: translationsData.description,
      termsAndConditions: translationsData.termsAndConditions
    };
    return this.apiService.post('service/translation', data);
  }

  updateTranslations(translationId: number, translationsData: any) {
    const data = {
      language: translationsData.language,
      title: translationsData.title,
      description: translationsData.description,
      termsAndConditions: translationsData.termsAndConditions
    };
    return this.apiService.put(`service/translation/${translationId}`, data);
  }

  deleteTranslation(translationId: number) {
    return this.apiService.delete(`service/translation/${translationId}`);
  }
}
