import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { default as clientsConfigurations } from './../style/clientsCss/clients-configurations.json';
import { AnalyticsService } from './dashboard/services/analytics.service';
import {
  AppEvents,
  EventBusService
} from './dashboard/services/event-bus.service';
import { PushNotificationService } from './dashboard/services/push-notification.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'marketplace-front';
  message;
  public clientCss: string;
  private listClients;
  private clientConfiguration = clientsConfigurations;
  private appkey;
  public gtag: Function;

  constructor(
    public translate: TranslateService,
    private analytics: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private pushNotification: PushNotificationService,
    private router: Router,
    private bus: EventBusService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setApplicationInsights();
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');
    //const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
    const appLang = JSON.parse(localStorage.getItem('selectedLanguage'));
    translate.use(appLang?.key.match(/en|es/) ? appLang?.key : 'en');
    //translate.use('en');

    this.analytics.checkIdAnalytics();
    this.listClients = this.clientConfiguration.map((a) => a.clientName);

    // change
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((r) => {
        this.bus.emit(AppEvents.router, r['url']);
      });
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.clientCss =
        params['client'] !== undefined ? params['client'] : 'default';
      this.clientCss = this.listClients.includes(this.clientCss)
        ? this.clientCss
        : 'default';
      localStorage.setItem('client', this.clientCss);
      if (this.clientCss) this.loadStyle(this.clientCss);
      this.setEnvironmentAppKeyValue(this.clientCss);

      this.setUpAnalytics();
      this.loadScript();
    });

    this.pushNotification.requestPermission();
    this.pushNotification.listen();
  }

  // set value of Environment Appkey depending on client value
  setEnvironmentAppKeyValue(clientName: string) {
    const clientInfo = this.clientConfiguration.find(
      (a) => a.clientName === clientName
    );
    environment.appKey = environment.production
      ? clientInfo.appKeyProd
      : clientInfo.appKeyDev;
  }
  //load the theme at runtime, based on router by invoking loadStyle('client css sheet name')
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    const themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.type = 'text/css';
      themeLink.href = `${styleName}.css`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = `${styleName}.css`;

      head.appendChild(style);
    }
  }

  setApplicationInsights() {
    const angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.insights.key,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  setUpAnalytics() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.gtag('config', environment.googleAnalitycs.gId, {
          page_path: event.urlAfterRedirects
        });
      });
  }

  private loadScript() {
    const head = this.document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://www.googletagmanager.com/gtag/js?id=' +
      environment.googleAnalitycs.gId +
      '';
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.innerHTML =
      ' window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}  gtag("js", new Date());  gtag("config", "' +
      environment.googleAnalitycs.gId +
      '")';

    if (head !== null && head !== undefined) {
      head.appendChild(script);
      head.appendChild(script2);
    }
  }
}
