import { AgmMap } from '@agm/core';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BranchDetail } from './../../../../models/branch.model';
@Component({
  selector: 'app-dialog-branch',
  templateUrl: './dialog-branch.component.html',
  styleUrls: ['./dialog-branch.component.scss']
})
export class DialogBranchComponent implements OnInit {
  public offer;
  public branchsList;
  public _mapWidth = '29.427vw';
  public _mapHeight = '27.031vw';
  public responsive = false;
  addressList = [];
  items = 4;
  page = 0;
  selectedAddress: any;
  classSelectedAddress: number;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this._mapWidth = event.target.innerWidth <= 414 ? '69vw' : '29.427vw';
    this._mapHeight = event.target.innerWidth <= 414 ? '40.097vw' : '29.427vw';
    this.responsive = event.target.innerWidth <= 414 ? true : false;
    this.items = event.target.innerWidth <= 414 ? 1000 : 4;
  }
  @ViewChild('mapDialog') public map: AgmMap;
  constructor(
    public dialogRef: MatDialogRef<DialogBranchComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      offer;
      branchs: [];
    }
  ) {}

  ngOnInit() {
    if (window.innerWidth <= 414) {
      this._mapWidth = '69vw';
      this._mapHeight = '166px';
      this.responsive = true;
      this.items = 1000;
    }
    this.branchsList = this.data.branchs;
    this.offer = this.data.offer;
    this.selectedAddress = this.branchsList.find(
      (_branch) => _branch.checked === true
    );
    this.fillObjectAddressForList();
  }

  fillObjectAddressForList() {
    this.branchsList.forEach((element) => {
      element.distanceKm =
        Math.round((element.distanceKm + Number.EPSILON) * 100) / 100;
      this.addressList.push(element);
    });
  }
  selectedaddress(address: BranchDetail, i: number) {
    this.selectedAddress = address;
    this.selectedAddress.checked = true;
    this.classSelectedAddress = i;
    this.addressList.forEach((element) => {
      element.checked = element.id == address.id ? true : false;
      element.location.zoom = 14;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getSelectedBranch(branch) {
    this.selectedAddress = branch;
    const pos = this.addressList.indexOf(branch);
    this.selectedaddress(branch, pos);
  }

  changeSelectedBranch() {
    this.dialogRef.close(this.selectedAddress);
  }
  sendEmail() {}
}
