import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertType } from '../components/alert/alert.component';
import { AuthService } from './auth.service';
import { AppEvents, EventBusService } from './event-bus.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  public cache: Map<string, HttpResponse<any>> = new Map();
  constructor(
    private authService: AuthService,
    private router: Router,
    private utilService: UtilService,
    private bus: EventBusService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //https://scotch.io/@vigneshsithirai/angular-6-7-http-client-interceptor-with-error-handling
    //https://medium.com/@johnmeguira/intercept-all-http-calls-with-angular-5-to-display-a-loader-281924b73ad8
    //https://medium.com/@zeljkoradic/loader-bar-on-every-http-request-in-angular-6-60d8572a21a9

    if (!req.headers.get('Content-Type') && !(req.body instanceof FormData))
      req = req.clone({
        headers: req.headers.append(
          'Content-Type',
          'application/json; charset=utf-8'
        )
      });

    //Add app client
    const appKey = environment.appKey;
    if (appKey)
      req = req.clone({ headers: req.headers.append('X-APP-KEY', appKey) });

    // Add idAnalytics to get user information(user location).
    const location = localStorage.getItem('location');
    if (location)
      req = req.clone({ headers: req.headers.append('location', location) });

    // Add language to get user information(user location).
    const locale = JSON.parse(localStorage.getItem('selectedLanguage'));
    if (locale)
      req = req.clone({
        headers: req.headers.append('locale', locale.localID)
      });

    req = req.clone({
      withCredentials: true
    });

    return next.handle(req).pipe(
      timeout(30000),
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((response) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          return this.handle401Error(req, next);
        } else {
          const messages =
            response?.error?.message ||
            this.TryJsonParse(response?.error)?.message ||
            response?.message ||
            'GENERICS.ERROR.TRY_LATER';

          if (Array.isArray(messages)) {
            messages.forEach((message: string, index: number) => {
              setTimeout(() => {
                this.utilService.alert(message, AlertType.danger);
              }, 100 * index);
            });
          } else {
            this.utilService.alert(messages, AlertType.danger);
          }
          throw response;
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.includes('/refresh')) {
      this.goToLogin();
      return EMPTY;
    }
    return this.authService.refreshToken().pipe(
      switchMap((response: any) => {
        if (response._httpResponseCode === 200) {
          const newRequest = request.clone();
          return next.handle(newRequest);
        }
      })
    );
  }
  private goToLogin() {
    localStorage.removeItem('userIdRole');
    this.bus.emit(AppEvents.update.profile, null);
    this.router.navigateByUrl('/webcliente/home');
    window.location.reload();
  }

  private TryJsonParse(json: string): any {
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  }
}
