import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  public location = {
    latitude: null,
    longitude: null,
    locality: ''
  };
  public geoCoder;
  latitude: number;
  longitude: number;

  constructor(private mapsAPILoader: MapsAPILoader) {}

  checkIdAnalytics() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    });
  }

  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder = new google.maps.Geocoder();
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const geocodeData = results[0].address_components.find((a) => {
              return a.types[0] === 'locality' || a.types[0] === 'country';
            });
            this.location.locality = geocodeData ? geocodeData.long_name : '';
            this.location.latitude = latitude;
            this.location.longitude = longitude;
            localStorage.setItem('location', JSON.stringify(this.location));
          }
        }
      }
    );
  }
}
