import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { AlertType } from 'src/app/dashboard/components/alert/alert.component';
import { OfferService } from 'src/app/dashboard/services/offer.service';
import { UtilService } from 'src/app/dashboard/services/util.service';

@Component({
  selector: 'app-dialog-code',
  templateUrl: './dialog-code.component.html',
  styleUrls: ['./dialog-code.component.scss']
})
export class DialogCodeComponent implements OnInit, OnDestroy {
  id: number;
  sizeQr = 131;
  typeCode: any;
  public isEvaluated = null;
  public canSendComment = false;
  public hideEvaluatedBtns = false;
  public emailForLater = '';
  public code;
  private subscription: Subscription;
  private voteId: number;
  constructor(
    public dialogRef: MatDialogRef<DialogCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public offerService: OfferService,
    private utilService: UtilService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}
  ngOnInit(): void {
    this.hideEvaluatedBtns = false;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  sendEmail() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.recaptchaV3Service.execute('sendEmail').subscribe(
      (token) => {
        this.sendEmailForLater();
      },
      (error) => {
        console.log(error);
        this.utilService.alert(
          'Error trying to verify request (reCaptcha v3)',
          AlertType.danger
        );
      }
    );
  }
  public sendOfferEvaluation(evaluation: boolean) {
    this.isEvaluated = evaluation;
    this.hideEvaluatedBtns = true;
    if (!evaluation) {
      this.canSendComment = true;
    }
    // ep call
    this.offerService
      .postVoteForOffer(
        this.data.offer.id,
        evaluation,
        this.data?.selectedBranch?.id
      )
      .subscribe(
        (result) => {
          this.voteId = result.id;
        },
        (error) => {
          if (error) {
            this.closeDialog();
            this.utilService.alert(
              'Error sending offers comment, please try again later',
              AlertType.danger
            );
          }
        }
      );
  }
  public sendCommentOfEvaluation(comment: string) {
    if (comment !== '') {
      this.offerService
        .postCommentForKoOffer(this.data.offer.id, {
          id: this.voteId,
          comment: comment
        })
        .subscribe(
          (result) => {
            this.utilService.alert('Offer Comment send', AlertType.success);
          },
          (error) => {
            if (error) {
              this.closeDialog();
              this.utilService.alert(
                'Error sending offers comment, please try again later',
                AlertType.danger
              );
            }
          }
        );
    }
    this.canSendComment = false;
    this.isEvaluated = true;
  }
  private sendEmailForLater() {
    this.offerService
      .postEmailForLater(
        this.data.code.code,
        this.data.offer.id,
        this.emailForLater
      )
      .subscribe(
        (response) => {
          this.closeDialog();
          this.utilService.alert('Offer Code send', AlertType.success);
        },
        (error) => {
          if (error) {
            this.closeDialog();
            this.utilService.alert(
              'Error sending offers code, please try again later',
              AlertType.danger
            );
          }
        }
      );
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
