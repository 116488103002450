import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import SwiperCore, {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-service-carousel',
  templateUrl: './service-carousel.component.html',
  styleUrls: ['./service-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceCarouselComponent implements OnInit {
  @Input() services;

  public scrollMove;
  public scrollLeft = false;
  public scrollRight = true;
  public client: string;
  public imageWidth;
  public imageHeight;
  public currency = environment.appCurrency;
  public config: SwiperOptions;

  public isLoadingServices = true;
  @ViewChild('swiperService', { static: false }) swiper?: SwiperComponent;
  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    if (this.services.count !== 0)
      this.config = {
        init: true,
        preloadImages: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        navigation: true,
        scrollbar: { draggable: true },
        centeredSlides: false,
        lazy: true
      };

    // get client for css
    this.client = `?client=${localStorage.getItem('client')}`;
    if (this.services) {
      this.isLoadingServices = false;
    }
    this.imageHeight = '221';
    this.imageWidth = '221';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.services) {
      this.swiper.swiperRef.update();
    }
  }

  goToDetailService(service) {
    const titleService = service.title
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-');
    const url = `/webcliente/services/${service.id}-${titleService}${this.client}`;
    this.router.navigateByUrl(url);
  }
  //swiperjs
  onSwiper([swiper]) {}
  onSlideChange() {}
}
