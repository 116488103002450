import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dashboard-dialog-management-prices',
  templateUrl: './dialog-management-prices.component.html',
  styleUrls: ['./dialog-management-prices.component.scss']
})
export class DialogManagementPriceComponent implements OnInit {
  public formPriceDuration: FormGroup;
  currency: string;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogManagementPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.currency = environment.appCurrency;
    this.createFormPrices();
  }

  createFormPrices() {
    this.formPriceDuration = this.fb.group({
      id: [this.data?.id || null],
      price: [this.data?.price || '', Validators.required],
      hours: [this.data?.duration.hours || 0, Validators.required],
      minutes: [this.data?.duration.minutes || 0, Validators.required]
    });
  }

  get formPriceDurationValid() {
    return (
      this.formPriceDuration.get('prices').invalid &&
      this.formPriceDuration.get('prices').touched
    );
  }
}
