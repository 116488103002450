import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) {}

  get<T = any>(uri: string, params?: any): Observable<T> {
    return this.http.get<T>(`${environment.ApiUrl}/${uri}`, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  post<T = any>(uri: string, payload: any, params?: any): Observable<T> {
    return this.http.post<T>(`${environment.ApiUrl}/${uri}`, payload, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  patch<T = any>(uri: string, payload: any, params?: any): Observable<T> {
    return this.http.patch<T>(`${environment.ApiUrl}/${uri}`, payload, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  put<T = any>(uri: string, payload: any, params?: any): Observable<T> {
    return this.http.put<T>(`${environment.ApiUrl}/${uri}`, payload, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  delete<T = any>(uri: string, params?: any): Observable<T> {
    return this.http.delete<T>(`${environment.ApiUrl}/${uri}`, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  buildPaginatedHttpParams(
    currentPage: number,
    pageSize: number,
    sortBy: string,
    sortDesc: boolean
  ): HttpParams {
    let params = new HttpParams();
    if (currentPage != undefined || currentPage != null)
      params = params.append('currentPage', currentPage.toString());
    if (pageSize) params = params.append('pageSize', pageSize.toString());
    if (sortBy) params = params.append('orderBy', sortBy);
    if (sortDesc != undefined || sortDesc != null)
      params = params.append('desc', sortDesc.toString());

    return params;
  }
}
