<app-custom-spinner
  *ngIf="!data.code"
  [overlayComplete]="true"
></app-custom-spinner>
<div class="containerCode" *ngIf="data.code">
  <div class="headerCode">
    <div class="captionStyle">{{ data.offer.caption }}</div>
    <span (click)="closeDialog()"><i class="fas fa-times iconClose"></i></span>
  </div>

  <div class="QRSpace" *ngIf="data?.code.codeType === 'QRCode'">
    <qrcode
      [qrdata]="data?.code.code"
      [width]="sizeQr"
      [errorCorrectionLevel]="'M'"
    ></qrcode>
  </div>
  <div
    class="codeSpace"
    *ngIf="data?.code?.codeType !== 'QRCode' && data?.code.codeType !== 'None'"
  >
    <div class="code">{{ data?.code.code }}</div>
    <div class="buttonCopy" [cdkCopyToClipboard]="data?.code.code">COPY</div>
  </div>
  <a
    *ngIf="
      data?.code.codeType !== 'QRCode' && data?.offer.offerType !== 'In Store'
    "
    class="buttonShop"
    target="_blank"
    href="{{ data?.offer.affiliateLink }}"
    >SHOP NOW</a
  >
  <div class="codeSpace">
    <input
      class="emailInput"
      type="text"
      placeholder="Email for later"
      [(ngModel)]="emailForLater"
    />
    <div class="buttonCopy" (click)="sendEmail()">SEND</div>
  </div>
  <div
    class="branches"
    *ngIf="
      data?.code.codeType === 'QRCode' || data?.offer.offerType === 'In Store'
    "
  >
    <div class="titleBr">Nearest Branch</div>
    <div class="textBr">
      <span style="display: inline-block">{{
        data?.selectedBranch?.address
      }}</span>
      <span class="distance">{{ data?.selectedBranch?.distanceKm }} km</span>
    </div>
  </div>

  <div class="checkWork">
    <h2 class="title" *ngIf="!hideEvaluatedBtns">Did it work?</h2>
    <div class="orderButtons" *ngIf="!hideEvaluatedBtns">
      <button
        class="buttonFeedback"
        (click)="sendOfferEvaluation(true)"
        [ngClass]="{ buttonOk: isEvaluated === true }"
      >
        <i class="far fa-thumbs-up iconValidate"></i>
        <span>Yes</span>
      </button>
      <button
        class="buttonFeedback"
        (click)="sendOfferEvaluation(false)"
        [ngClass]="{ buttonKo: isEvaluated === false }"
      >
        <i class="far fa-thumbs-down iconValidate"></i>
        <span>No</span>
      </button>
    </div>
    <div
      class="thanksMens d-flex justify-content-center mt-4"
      *ngIf="isEvaluated"
    >
      <h3>Thanks for your feedback</h3>
    </div>
    <div class="commentSection" *ngIf="canSendComment">
      <h3 class="titleComment mt-3">What happened?</h3>
      <textarea rows="4" class="commentTextArea" #offerKoComment></textarea>
      <button
        class="buttonFeedback"
        (click)="sendCommentOfEvaluation(offerKoComment.value)"
      >
        <span class="sendComment">SEND COMMENT </span>
      </button>
    </div>
  </div>
</div>
