<div class="containerService">
  <app-custom-spinner *ngIf="isLoadingServices"></app-custom-spinner>
  <div *ngIf="!isLoadingServices" class="serviceCard" id="serviceSlide">
    <ng-container *ngIf="services.count !== 0; else noOffer">
      <swiper
        class="mySwiper"
        #swiperService
        [config]="config"
        [grabCursor]="true"
        (swiper)="onSwiper($event)"
        (slideChange)="onSlideChange()"
      >
        <ng-template swiperSlide *ngFor="let serviceData of services">
          <div
            class="card"
            (click)="$event.preventDefault(); goToDetailService(serviceData)"
          >
            <img
              class="backimage"
              [src]="serviceData?.image || 'assets/img/no-image.svg'"
              [width]="imageWidth"
              [height]="imageHeight"
            />
            <div class="card-body p-2" style="text-align: center">
              <p class="card-title">{{ serviceData.title }}</p>
              <p class="card-text">
                {{ serviceData.price[0]?.price | currency: currency:'symbol' }}
              </p>
            </div>
          </div>
        </ng-template>
      </swiper>
    </ng-container>
    <!-- template no services-->
    <ng-template #noService>
      <div class="text-center">
        <img
          class="rounded-circle mt-5"
          width="80"
          height="80"
          src="assets/img/dashboard-no-data.svg"
        />
        <h4>No data available</h4>
      </div>
    </ng-template>
  </div>
</div>
