<div class="mapBranch">
  <div class="agmMap">
    <agm-map
      #gm
      [latitude]="selectedMapPoint?.location.latitude"
      [longitude]="selectedMapPoint?.location.longitude"
      [zoom]="selectedMapPoint?.location.zoom"
      [disableDefaultUI]="desactiveControls"
      [ngStyle]="{ width: mapWidth, height: mapHeight }"
    >
      <agm-overlay
        *ngFor="let marker of addressList"
        [latitude]="marker.location?.latitude"
        [longitude]="marker.location?.longitude"
        (markerClick)="selectMarkerBranch(infoWindow, gm, marker, mapMarker)"
        #mapMarkeroverlay
      >
        <div class="map-branch" *ngIf="marker.merchant.image">
          <div class="map-branch-inner">
            <div class="map-branch-logo">
              <img
                [src]="
                  marker.merchant.image ? marker.merchant.image : iconMap.url
                "
                class="map-branch-logo-image"
              />
            </div>
          </div>
        </div>
        <div *ngIf="!marker.merchant.image">
          <img [src]="iconMap.url" class="noLogo" />
        </div>

        <agm-info-window
          [maxWidth]="360"
          [closeWhenOthersOpen]="true"
          class="infoWindowStyle"
          [isOpen]="marker.checked"
          #infoWindow
        >
          <div class="cardBranchInfo">
            <div class="cardtitle col-12">
              <div class="row agmContentTitle col-12">
                <!-- <div class="col-2" *ngIf="marker.imgOffer">
                  <img
                    [src]="marker.imgOffer"
                    alt=""
                    width="40px"
                    height="40px"
                  /> -->

                <div class="col-2" *ngIf="marker.merchant.image">
                  <img
                    [src]="marker.merchant.image"
                    alt=""
                    width="40px"
                    height="40px"
                  />
                </div>
                <div class="col-9 ml-2">
                  <p style="font-weight: bold">{{ marker.name }}</p>
                </div>
              </div>
            </div>
            <br />
            <div class="cardBody row col-12">
              <div class="col-12 row">
                <p class="col-8">{{ marker.address }}</p>
                <p class="col-4">{{ marker.distanceKm }} km</p>
              </div>
              <div class="col-12">
                <p *ngIf="!marker.internalUrl" style="font-weight: bold">
                  {{ marker.offer?.title }}
                </p>
                <a *ngIf="marker.internalUrl" [href]="marker.internalUrl">{{
                  marker.offer?.title
                }}</a>
              </div>
            </div>
          </div>
        </agm-info-window>
      </agm-overlay>
    </agm-map>
  </div>
</div>
