<div class="dialog-add-language">
  <div
    class="d-flex flex-row justify-content-between align-items-baseline mb-20px"
  >
    <h2 mat-dialog-title class="text-uppercase p-0 mb-0">
      {{ 'GENERICS.LANGUAGE' | translate }}
    </h2>
    <div mat-dialog-actions>
      <button
        mat-button
        mat-dialog-close
        class="c-dialog-add-category__button-close"
      >
        <span class="fas fa-times"></span>
      </button>
    </div>
  </div>
  <app-datatable-language
    (languageChanged)="languageSelectChangedHandler($event)"
    [languageJustSelected]="data"
  ></app-datatable-language>
  <div mat-dialog-actions class="c-dialog-add-tag__actions-bottom">
    <button
      class="btn btn-primary--active"
      mat-button
      [mat-dialog-close]="selectedLanguage"
    >
      {{ 'GENERICS.SELECT' | translate }}
    </button>
  </div>
</div>
