<input #inputImage hidden [id]="dId" (change)="convert($event)" type="file" />

<div
  dragAndDrop
  (onFileDropped)="convert($event)"
  *ngIf="!base64"
  [ngStyle]="{ width: width, height: height }"
  class="d-inline-block"
>
  <div class="container">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <i class="fas fa-fw fa-cloud-upload-alt"></i>
      <span class="align-middle title">Drag and Drop</span>
      <span class="subtitle">JPG, GIF or PNG. Max Size of 800K and</span>
      <span class="subtitle">min. {{ height }} x {{ width }}</span>
      <div class="d-flex flex-row justify-content-center upload-button">
        <label
          [for]="dId"
          class="
            btn
            btn-secondary--active
            mt-3
            d-flex
            flex-row
            justify-content-center
            align-items-center
          "
        >
          <span class="fas fa-upload"></span>
          <span class="text-upload">Upload</span>
        </label>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="base64"
  [ngStyle]="{ width: width, height: height }"
  class="d-inline-block"
  [ngClass]="imgOffer ? 'img-container-product' : 'img-container'"
>
  <img [src]="base64 | sanitize" />
</div>

<div
  *ngIf="base64 && editable"
  class="d-flex flex-row justify-content-center"
  [ngStyle]="{ width: width }"
>
  <div class="actionRefresh">
    <label [for]="dId" style="cursor: pointer">
      <span class="fas fa-sync"></span>
      <span>Replace</span>
    </label>
  </div>
  <div class="actionDelete" (click)="remove()">
    <span class="fas fa-trash"></span>
    <span>Delete</span>
  </div>
</div>
