<app-custom-datatable
  [data]="data"
  [totalRecords]="totalRecords"
  [columns]="cols"
  [settings]="settings"
  (loadData)="loadData($event)"
>
  <ng-template table-column="date" let-value="value" let-row="row">
    <p>{{ value | date: 'dd/MMM/yyyy' }}</p>
  </ng-template>
  <ng-template table-column="title" let-value="value" let-row="row">
    <a
      [routerLink]="[(row.title | kebabCase) + '/' + row.id]"
      [state]="row"
      routerLinkActive="router-link-active"
      >{{ value }}</a
    >
  </ng-template>
</app-custom-datatable>
