import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PushNotificationModel } from './../../../models/push-notification.model';
import { PushNotificationService } from './../../../services/push-notification.service';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PushNotificationComponent implements OnInit {
  showPanel: boolean;
  notification: PushNotificationModel;
  notificationSub: Subscription;
  notificationTimeout: any;
  client;

  constructor(
    private notificationService: PushNotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.client = `?client=${localStorage.getItem('client')}`;

    this.notificationService.getNotification().subscribe((n) => {
      this.notification = n;
      this.showPanel = n !== null;

      this.notificationTimeout = setTimeout(() => {
        this.showPanel = false;
      }, 6000);
    });
  }

  goToOffer() {
    if (this.notification.data) {
      const titleMerchant = this.notification.data.merchant
        .replaceAll(' ', '-')
        .replaceAll('%', '');
      const titleOffer = this.notification.data.title
        .replaceAll(' ', '-')
        .replaceAll('%', '');

      this.router.navigateByUrl(
        '/webcliente/offer/' +
          this.notification.data.id +
          '-' +
          titleMerchant +
          '-' +
          titleOffer +
          this.client
      );
    }
  }
  dismissNotification() {
    this.showPanel = false;
  }

  ngOnDestroy() {
    this.notificationSub.unsubscribe();
    clearTimeout(this.notificationTimeout);
  }
}
