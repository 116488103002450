import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PaginatedResponse } from '../models/paginated.model';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  constructor() {}

  get url(): string {
    return `${environment.ApiUrl}/languages`;
  }

  getPaginated = <T = any>(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false
  ) => {
    return this.getLanguages();
  };

  getLanguages(): Observable<PaginatedResponse> {
    const data = [
      {
        id: 0,
        flag: 'assets/img/flags/en.png',
        name: 'English',
        key: 'en',
        iso: 'UK'
      },
      {
        id: 1,
        flag: 'assets/img/flags/es.png',
        name: 'Spanish',
        key: 'es',
        iso: 'ES'
      },
      {
        id: 2,
        flag: 'assets/img/flags/fr.png',
        name: 'French',
        key: 'fr',
        iso: 'FR'
      },
      {
        id: 3,
        flag: 'assets/img/flags/de.png',
        name: 'Germany',
        key: 'de',
        iso: 'DE'
      }
    ];
    const languageObject: PaginatedResponse = {
      count: data.length,
      data: data
    };
    return of(languageObject);
  }
}
