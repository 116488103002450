import { Component, Input, OnInit } from '@angular/core';
import {
  DatatableColumns,
  DatatableSettings
} from '../../../../models/datatable.model';
import { ServiceDetail } from '../../../../models/service.model';
import { TooltipComponent } from '../../tooltip/tooltip.component';

@Component({
  selector: 'app-datatable-worker-service-location',
  templateUrl: './datatable-worker-service-location.component.html',
  styleUrls: ['./datatable-worker-service-location.component.scss']
})
export class DatatableWorkerServiceLocationComponent implements OnInit {
  @Input() service: ServiceDetail;
  public data: any;
  public totalRecords: number;
  public cols: DatatableColumns[];
  public settings: DatatableSettings;
  public radiusService;
  _tooltipComponent = TooltipComponent;

  constructor() {}

  ngOnInit(): void {
    this.cols = [
      { display: 'SERVICE.ADDRESS', field: 'address' },
      { display: 'GENERICS.TYPE', field: 'type' },
      { display: 'SERVICE.LOCATION', field: 'location' },
      { display: 'SERVICE.RADIUS', field: 'radius' }
    ];

    this.settings = {
      rows: 10,
      search: true,
      paginator: true,
      selectRows: false,
      defaultSort: 'address'
    };
  }

  async loadData(event) {
    this.totalRecords = this.service.location.length;
    this.data = this.service.location;
    this.radiusService = this.service.radius;
  }
}
