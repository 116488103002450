import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { ServiceLocation } from '../models/service.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private readonly uri = 'location';
  private readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private api: ApiService) {}

  create(location: ServiceLocation) {
    return this.api.post(this.uri, location);
  }

  getById(locationId: number) {
    return this.api.get(`${this.uri}/${locationId}`);
  }

  update(location: ServiceLocation, locationId: number) {
    return this.api.put(`${this.uri}/${locationId}`, location);
  }

  delete(locationId: number) {
    return this.api.delete(`${this.uri}/${locationId}`);
  }
}
