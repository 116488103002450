import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
@Component({
  selector: 'dashboard-dialog-manage-service-calendar-times',
  templateUrl: './dialog-manage-service-calendar-times.component.html',
  styleUrls: ['./dialog-manage-service-calendar-times.component.scss']
})
export class DialogManagerServiceCalendarTimesComponent implements OnInit {
  public formTimes: FormGroup;
  public buttonText: string;
  daysHead: any[] = [];
  startTime;
  endTime;
  selectedWeekDays: any[] = [];
  minDate;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogManagerServiceCalendarTimesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ) {
    this.buttonText = this.data
      ? this.translateService.instant('GENERICS.SAVE')
      : this.translateService.instant('GENERICS.ADD');
  }

  ngOnInit(): void {
    this.minDate = new Date();
    this.daysHead = [
      { id: 'Monday', translate: 'SERVICE.CALENDAR.MONDAY' },
      {
        id: 'Tuesday',
        translate: 'SERVICE.CALENDAR.TUESDAY'
      },
      {
        id: 'Wednesday',
        translate: 'SERVICE.CALENDAR.WEDNESDAY'
      },
      {
        id: 'Thursday',
        translate: 'SERVICE.CALENDAR.THURSDAY'
      },
      { id: 'Friday', translate: 'SERVICE.CALENDAR.FRIDAY' },
      {
        id: 'Saturday',
        translate: 'SERVICE.CALENDAR.SATURDAY'
      },
      { id: 'Sunday', translate: 'SERVICE.CALENDAR.SUNDAY' }
    ];
    this.initForm();
    this.initWeekDay();
  }

  initForm() {
    this.formTimes = this.fb.group({
      startTime: [this.data?.startTime || null, [Validators.required]],
      endTime: [this.data?.endTime || null, [Validators.required]],
      fromDate: [this.data?.fromDate || null, [Validators.required]],
      toDate: [this.data?.toDate || null, [Validators.required]],
      weekdays: this.fb.array(
        [],
        [Validators.required, Validators.minLength(1)]
      )
    });
  }
  initWeekDay() {
    if (this.data) {
      const selectedDayWeek = this.daysHead.map((x) => {
        if (x.id === this.data.dayOfWeek.weekday) {
          x.checked = true;
        } else {
          x.checked = false;
        }
        return x;
      });
      const value = selectedDayWeek.find(
        (x) => x.id === this.data.dayOfWeek.weekday
      );
      const selectedWeekDays = this.formTimes.controls.weekdays as FormArray;
      selectedWeekDays.push(new FormControl(value));
      this.formTimes.patchValue({
        weekdays: selectedWeekDays
      });
    }
  }

  onCheckboxChange(event: any) {
    const selectedWeekDays = this.formTimes.controls.weekdays as FormArray;
    if (event.checked) {
      selectedWeekDays.push(new FormControl(event.value));
    } else {
      const index = selectedWeekDays.controls.findIndex(
        (x) => x.value === event.value
      );
      selectedWeekDays.removeAt(index);
    }
    this.formTimes.patchValue({
      weekdays: selectedWeekDays
    });
  }

  get groupValidator() {
    const fromCtrl = this.formTimes.get('startTime');
    const toCtrl = this.formTimes.get('endTime');
    if (fromCtrl.value !== null && toCtrl.value !== null) {
      const beginningTime = moment({
        h: fromCtrl.value.hour,
        s: fromCtrl.value.minute
      });
      const endTime = moment({
        h: toCtrl.value.hour,
        s: toCtrl.value.minute
      });
      return beginningTime.isBefore(endTime) && this.formTimes.valid;
    } else {
      return false;
    }
  }
  get startTimeValid() {
    return (
      this.formTimes.get('startTime').invalid &&
      this.formTimes.get('startTime').touched
    );
  }
  get endTimeValid() {
    return (
      this.formTimes.get('endTime').invalid &&
      this.formTimes.get('endTime').touched
    );
  }
  get dayWeekValid() {
    return (
      this.formTimes.get('weekdays').invalid &&
      this.formTimes.get('weekdays').touched
    );
  }
  get fromDateValid() {
    return (
      this.formTimes.get('fromDate').invalid &&
      this.formTimes.get('fromDate').touched
    );
  }
  get toDateValid() {
    return (
      this.formTimes.get('toDate').invalid &&
      this.formTimes.get('toDate').touched
    );
  }
  get arrWeekDay(): FormArray {
    return this.formTimes.get('weekdays') as FormArray;
  }

  changeDatePicker(event: any) {
    if (event.value) {
      this.formTimes.patchValue({
        fromDate: new Date(this.formTimes.value.fromDate),
        toDate: new Date(this.formTimes.value.toDate)
      });
    }
  }
}
