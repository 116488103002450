// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  type: 'DEVELOPMENT',
  ApiUrl: 'https://api.public.marketplace.ustspain.es',
  appKey: '',
  apiMap: 'AIzaSyA_xecrOwhe0xKrjHOvWZ4aL4qYJ3hxPEs',
  algolia: {
    appId: '52JFYDST93',
    apiKey: '8b4d7248143d29eddb599b40963aa3f4'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCL9iaBi0Q5VahLriJIaErI8By3XJviLBU',
    authDomain: 'marketplace-daa74.firebaseapp.com',
    projectId: 'marketplace-daa74',
    storageBucket: 'marketplace-daa74.appspot.com',
    messagingSenderId: '713769603465',
    appId: '1:713769603465:web:e360445de51a119b9ed158',
    measurementId: 'G-R7R2VSELNP'
  },
  vapidKey:
    'BLS-2GZ7z4hIDL3WMcZHUh6xucbmUcAEob6IV-e79WsjKkMe6EV0o0APj_Ua6AuONJJLxgaxZGu_UL13tR-ipeA',
  googleCaptcha: {
    siteKey: '6LcMICYcAAAAAOwjOwcqUAMCyZ-27fOzsEzmCSUU',
    secretKey: '6LcMICYcAAAAAG9KnUxZoR7letpODEpqBatec-2l'
  },
  insights: {
    key: '8942638d-e9a9-4d3a-bacf-eacbb920e1bf'
  },
  googleAnalitycs: {
    gId: 'G-QZPS3DE5Z3'
  },
  defaultLanguage: {
    id: 0,
    key: 'en',
    name: 'English',
    iso: 'UK',
    isDefault: true,
    localID: 'en-UK'
  },
  appLanguages: [
    {
      id: 0,
      key: 'en',
      name: 'English',
      iso: 'UK',
      isDefault: true,
      localID: 'en-UK'
    },
    {
      id: 1,
      key: 'es',
      name: 'Spanish',
      iso: 'ES',
      isDefault: true,
      localID: 'es-ES'
    }
    // {
    //   id: 2,
    //   key: 'fr',
    //   name: 'French',
    //   iso: 'FR',
    //   isDefault: false,
    //   localID: 'fr-FR'
    // },
    // {
    //   id: 3,
    //   key: 'de',
    //   name: 'Germany',
    //   iso: 'DE',
    //   isDefault: false,
    //   localID: 'de-DE'
    // }
  ],
  appVersion: '1.0.4',
  appCurrency: 'EUR'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
