import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryDetails } from '../models/category.model';
import { PaginatedResponse } from '../models/paginated.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CategorieService {
  private readonly uri = 'category';

  constructor(private apiService: ApiService) {}

  getPaginated(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false
  ): Observable<PaginatedResponse> {
    const params = this.apiService.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    return this.apiService.get(this.uri, params);
  }

  getCategoryById(id: number): Observable<CategoryDetails> {
    return this.apiService.get(`${this.uri}/${id}`);
  }

  getOffersByCategory(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false,
    id: number,
    filterBy?: ''
  ): Observable<PaginatedResponse> {
    const params = this.apiService.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    const url = filterBy
      ? `${this.uri}/${id}/offer?filter=${filterBy}`
      : `${this.uri}/${id}/offer`;
    return this.apiService.get<any>(url, params);
  }
}
