import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { PushNotificationModel } from '../models/push-notification.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  currentMessage: BehaviorSubject<PushNotificationModel> =
    new BehaviorSubject<PushNotificationModel>(null);
  private readonly uri = 'tools';

  constructor(
    private messaging: AngularFireMessaging,
    private apiService: ApiService
  ) {}

  requestPermission() {
    this.messaging.requestToken.subscribe(
      (token) => {
        if (token) this.subcribeNotifications(token).subscribe();
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  subcribeNotifications(_token: string) {
    return this.apiService.post<any>(`${this.uri}/notification/subscribe`, {
      token: _token
    });
  }
  receiveMessage() {
    this.messaging.onMessage((payload) => {
      this.currentMessage.next(payload);
    });
  }
  setNotification(notification: PushNotificationModel) {
    this.currentMessage.next(notification);
  }
  getNotification() {
    return this.currentMessage.asObservable();
  }

  listen() {
    this.messaging.messages.subscribe((message: any) => {
      this.setNotification({
        body: message.notification.body,
        title: message.notification.title,
        data: message.data,
        isVisible: true
      });
    });
  }
}
