<ng-container *ngIf="overlayComplete;else overlayOnlyParentDiv">
	<div class="overlay">
		<div class="center">
			<ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
		</div>
	</div>
</ng-container>
<ng-template #overlayOnlyParentDiv>
    <ng-container *ngIf="overlayOnlyDiv;else progressSpinner">
		<div class="spinner-wrapper">
			<ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
		</div>
	</ng-container>
</ng-template>
	<ng-template  #progressSpinner>
		<div class="normalSpinner">
			<mat-progress-spinner 
			[diameter]="diameter"
			[mode]="mode" 
			[strokeWidth]="strokeWidth"
			[value]="value">
		   </mat-progress-spinner>
		</div>
	</ng-template>