import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AppEvents, EventBusService } from '../../services/event-bus.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('0.3s ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('0.3s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class AlertComponent implements OnInit {
  alerts: Alert[] = [];
  defaultDelay = 5000;

  constructor(private bus: EventBusService) {}

  ngOnInit() {
    this.bus.on(AppEvents.alert).subscribe((alert: Alert) => {
      this.alerts.push(alert);
      setTimeout(() => {
        this.close(alert);
      }, alert.delay || this.defaultDelay);
    });
  }

  close(alert: Alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}

export interface Alert {
  type: AlertType;
  message: string;
  delay?: number;
}
export enum AlertType {
  success = 'alert-success',
  info = 'alert-info',
  warning = 'alert-warning',
  danger = 'alert-danger',
  primary = 'alert-primary',
  secondary = 'alert-secondary',
  light = 'alert-light',
  dark = 'alert-dark'
}
