<app-custom-datatable
  [data]="data"
  [totalRecords]="totalRecords"
  [columns]="cols"
  [settings]="settings"
  (loadData)="loadData($event)"
  (buttonClick)="deleteService($event)"
>
  <ng-template table-column="title" let-value="value" let-row="row">
    <a
      [routerLink]="[(row.title | kebabCase) + '/' + row.id]"
      [state]="row"
      routerLinkActive="router-link-active"
      >{{ value }}</a
    >
  </ng-template>

  <ng-template table-column="image" let-value="value" let-row="row">
    <div class="text-center">
      <img
        *ngIf="value"
        class="fitContain"
        width="60"
        height="60"
        [src]="value | sanitize"
      />

      <img
        *ngIf="!value || value === ''"
        class="fitContain"
        width="60"
        height="60"
        src="assets/img/no-image.svg"
      />
    </div>
  </ng-template>

  <ng-template table-column="price" let-value="value">
    <span *ngIf="value?.length">
      {{ 'SERVICE.FROM' | translate }}
      {{ value?.[0].price }} €
    </span>
    <span *ngIf="!value?.length">N/A</span>
  </ng-template>

  <ng-template table-column="availableLanguage" let-value="value">
    <div
      *ngIf="value?.length > 2"
      class="text-center d-flex align-items-center justify-content-start pointerDiv"
      [appCustomTooltip]="_tooltipComponent"
      content="{{ value }}"
    >
      <img
        *ngFor="let lang of value | slice: 0:2"
        [src]="'assets/img/flags/' + lang.split('-')[0] + '.png'"
        [alt]="lang"
        width="20"
        class="rounded-circle flagImg ml-2"
      />
      <p class="langLength ml-2">+ {{ value.length - 2 }}</p>
    </div>
    <div
      *ngIf="value?.length <= 2"
      class="text-center d-flex align-items-center justify-content-start"
    >
      <img
        *ngFor="let lang of value"
        [src]="'assets/img/flags/' + lang.split('-')[0] + '.png'"
        [alt]="lang"
        width="20"
        class="rounded-circle flagImg ml-2"
      />
    </div>
  </ng-template>

  <ng-template table-column="active" let-value="value">
    <span *ngIf="value" class="c-service__active">{{
      'GENERICS.ACTIVE' | translate
    }}</span>
    <span *ngIf="!value" class="c-service__inactive">{{
      'GENERICS.INACTIVE' | translate
    }}</span>
  </ng-template>
</app-custom-datatable>
