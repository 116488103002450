<div class="alert-container">
    <div *ngFor="let alert of alerts" [@inOutAnimation]>
      <div [ngClass]="alert.type" class="alert alert-dismissible fade show" role="alert">
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        <div class="alert-icon">
          <i class="far fa-fw fa-bell"></i>
        </div>
        <div class="alert-message">
          {{alert.message | translate}}
        </div>
      </div>
    </div>
  </div>