import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
@Component({
  selector: 'app-dialog-manage-service-exceptions',
  templateUrl: './dialog-manage-service-exceptions.component.html',
  styleUrls: ['./dialog-manage-service-exceptions.component.scss']
})
export class DialogManagerServiceExceptionsComponent implements OnInit {
  public formException: FormGroup;
  public buttonText: string;

  minDate: Date;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogManagerServiceExceptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ) {
    this.buttonText = this.data
      ? this.translateService.instant('GENERICS.SAVE')
      : this.translateService.instant('GENERICS.ADD');
  }

  ngOnInit(): void {
    this.buildInitMinDate();
    this.initForm();
  }

  initForm() {
    const today = new Date();
    this.formException = this.fb.group({
      startDate: [
        new Date(this.data?.startDate) || today,
        [Validators.required]
      ],
      endDate: [new Date(this.data?.endDate) || today, [Validators.required]],
      startTime: [this.data?.startTime, [Validators.required]],
      endTime: [this.data?.endTime, [Validators.required]],
      exceptionType: [this.data?.exceptionType || false, [Validators.required]]
    });
  }
  private buildInitMinDate() {
    const year = Number(new Date().getFullYear());
    const month = Number(new Date().getMonth());
    const day = Number(new Date().getUTCDate());
    this.minDate = new Date(year, month, day);
  }
  changeDatePicker(event: any) {
    if (event.value) {
      this.formException.patchValue({
        startDate: new Date(this.formException.value.startDate.toDate()),
        endDate: new Date(this.formException.value.endDate.toDate())
      });
    }
  }

  get startTimeValid() {
    return (
      this.formException.get('startTime').invalid &&
      this.formException.get('startTime').touched
    );
  }
  get endTimeValid() {
    return (
      this.formException.get('endTime').invalid &&
      this.formException.get('endTime').touched
    );
  }
  get groupValidator() {
    const fromCtrl = this.formException.get('startTime');
    const toCtrl = this.formException.get('endTime');
    if (fromCtrl.value !== null && toCtrl.value !== null) {
      const beginningTime = moment({
        h: fromCtrl.value.hour,
        s: fromCtrl.value.minute
      });
      const endTime = moment({
        h: toCtrl.value.hour,
        s: toCtrl.value.minute
      });

      return beginningTime.isBefore(endTime) && this.formException.valid;
    }
  }
}
