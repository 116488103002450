import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertType } from '../../../../components/alert/alert.component';
import { UserService } from '../../../../services/user.service';
import { UtilService } from '../../../../services/util.service';
@Component({
  selector: 'app-dialog-change-password',
  templateUrl: './dialog-change-password.component.html',
  styleUrls: ['./dialog-change-password.component.scss']
})
export class DialogChangePasswordsComponent implements OnInit {
  formChangePassword: FormGroup;
  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private userService: UserService,
    public dialogRef: MatDialogRef<DialogChangePasswordsComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.loadForm();
  }
  loadForm() {
    const reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])');
    this.formChangePassword = this.fb.group(
      {
        oldPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern(reg)
          ]
        ],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.pattern(reg)
          ]
        ]
      },
      { validators: [this.checkPasswords] }
    );
  }

  checkPasswords(group: FormGroup) {
    if (group.get('oldPassword').value !== group.get('newPassword').value) {
      return null;
    } else {
      return { itSame: true };
    }
  }

  async changePassword(): Promise<any> {
    if (this.formChangePassword.valid) {
      const data = {
        email: this.data.email,
        password: this.formChangePassword.get('newPassword').value,
        currentPassword: this.formChangePassword.get('oldPassword').value
      };
      this.userService.update(data).subscribe(
        (res) => {
          this.closeDialog();
        },
        (error) => {
          if (error) {
            this.utilService.alert('Error change password', AlertType.danger);
          }
        }
      );
    } else {
      this.utilService.alert('Form not valid', AlertType.danger);
      return null;
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
