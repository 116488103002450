<app-custom-datatable
  [data]="data"
  [totalRecords]="totalRecords"
  [columns]="cols"
  [settings]="settings"
  (loadData)="loadData($event)"
>
  <ng-template table-column="address" let-value="value">
    <p>
      {{ value }}
    </p>
  </ng-template>
  <ng-template table-column="type" let-value="value">
    <p *ngIf="value === 'AtBranch'">
      {{ 'SERVICE.LOCATIONTYPE.ATLOCATION' | translate }}
    </p>
    <p *ngIf="value === 'AtHome'">
      {{ 'SERVICE.LOCATIONTYPE.ATHOME' | translate }}
    </p>
  </ng-template>
  <ng-template table-column="location" let-value="value" let-row="row">
    <span>Lat: {{ row.location.latitude }}</span>
    <p>Long: {{ row.location.longitude }}</p>
  </ng-template>
  <ng-template table-column="radius" let-row="row">
    <div *ngIf="row.type === 'AtHome'">
      <p *ngIf="radiusService !== 0">{{ radiusService }} m</p>
      <p *ngIf="radiusService === 0">-</p>
    </div>
  </ng-template>
</app-custom-datatable>
