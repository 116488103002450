import { Component, OnInit } from '@angular/core';
import {
  DatatableColumns,
  DatatableSettings
} from '../../../../models/datatable.model';
import { WorkerService } from '../../../../services/worker.service';
import { TooltipComponent } from '../../tooltip/tooltip.component';

@Component({
  selector: 'app-datatable-worker-last-booking',
  templateUrl: './datatable-worker-last-booking.component.html',
  styleUrls: ['./datatable-worker-last-booking.component.scss']
})
export class DatatableWorkerLastBookingComponent implements OnInit {
  public data: any;
  public totalRecords: number;
  public cols: DatatableColumns[];
  public settings: DatatableSettings;

  _tooltipComponent = TooltipComponent;

  constructor(private workerService: WorkerService) {}

  ngOnInit(): void {
    this.cols = [
      { display: 'GENERICS.DATE', field: 'date' },
      { display: 'SERVICE.SERVICE', field: 'title' }
    ];

    this.settings = {
      rows: 5,
      search: false,
      paginator: false,
      defaultSort: 'date'
    };
  }

  async loadData(event) {
    const response = await this.workerService
      .getWorkerBookingPaginated(
        event?.pageSize,
        event?.currentPage,
        event?.orderBy,
        event?.desc
      )
      .toPromise();

    this.totalRecords = response.count;
    this.data = response.data;
  }
}
