<app-custom-datatable
  [data]="data"
  [totalRecords]="totalRecords"
  [columns]="cols"
  [settings]="settings"
  (loadData)="loadData($event)"
  (selectedRow)="rowSelected($event)"
>
  <ng-template table-column="flag" let-value="value">
    <div class="text-center">
      <img
        class="img-fluid mb-2"
        width="50"
        height="50"
        [src]="value | sanitize"
      />
    </div>
  </ng-template>
</app-custom-datatable>
