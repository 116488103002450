import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { OfferService } from '../../../../services/offer.service';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-hero-banner-carousel',
  templateUrl: './hero-banner-carousel.component.html',
  styleUrls: ['./hero-banner-carousel.component.scss'],
  providers: [NgbCarouselConfig]
})
export class HeroBannerCarouselComponent implements OnInit, OnChanges {
  @Input() offerTypeFilter;
  @Input() offersType: string;

  public hero = false;
  public imageWidth = '';
  public imageHeight = '';
  public client: string;
  public heroBanneOffers = {
    count: 0,
    data: []
  };

  public isLoadingHeroBanners = true;
  constructor(
    private readonly router: Router,
    private offer: OfferService,
    config: NgbCarouselConfig
  ) {
    config.interval = 0;
    config.animation = false;
    config.showNavigationArrows = false;
  }

  ngOnInit(): void {
    // get client for css
    this.client = `?client=${localStorage.getItem('client')}`;

    this.hero = this.offersType === 'hero' ? true : false;
    switch (this.offersType) {
      case 'hero':
        this.getOffersWithHeroBanner();
        this.imageHeight = '275';
        this.imageWidth = '975';
        break;
      default:
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.offerTypeFilter) {
      this.offersType === 'hero' ? this.getOffersWithHeroBanner() : '';
    }

    if (changes.offersType) {
      this.hero = this.offersType === 'hero' ? true : false;
    }
  }

  getOffersWithHeroBanner() {
    this.isLoadingHeroBanners = true;
    this.offer
      .getOffersWithHeroBanner(0, 0, '', false, this.offerTypeFilter)
      .subscribe((result) => {
        if (result) {
          this.isLoadingHeroBanners = false;
          this.heroBanneOffers.data = result.data;
          this.heroBanneOffers.count = result.count;
        }
      });
  }

  goToDetailOffer(offer) {
    const titleMerchant = offer.merchant.tradingName
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-');
    const titleOffer = offer.title
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-');
    const url = `/webcliente/offer/${offer.id}-${titleMerchant}-${titleOffer}${this.client}`;
    this.router.navigateByUrl(url);
  }
}
