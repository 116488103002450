import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef
} from '@angular/core';
import { AlertType } from 'src/app/dashboard/components/alert/alert.component';
import { UtilService } from 'src/app/dashboard/services/util.service';
import { Image } from './../../../models/image.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  @ViewChild('inputImage') inputImage: ElementRef<any>;

  private readonly whiteTypes = ['image/png', 'image/jpeg', 'image/gif'];
  private readonly maxSize = 800000;
  private readonly minResolution: number = 200;
  public imgOffer: boolean;

  @Input() imageId: number;
  @Input() base64: string;
  @Input() dId: string =
    'file-upload-' +
    new Date().getUTCMilliseconds() +
    Math.floor(Math.random() * 1000);
  @Input() width = '200px';
  @Input() height = '200px';
  @Input() editable = true;
  @Output() onConverted = new EventEmitter<Image>();

  constructor(private utilService: UtilService, private router: Router) {}

  ngOnInit(): void {
    this.urlCheck();
  }

  urlCheck() {
    const url = this.router.url.split('/');
    this.imgOffer = url.includes('offers');
  }

  convert(e) {
    const [file] = e?.target?.files || e;

    if (!file) return;

    if (file.size > this.maxSize) {
      this.utilService.alert(
        'GENERICS.ERROR.IMAGE.NOT_VALID_SIZE',
        AlertType.danger
      );
    } else if (this.whiteTypes.some((type) => type === file.type)) {
      // Creamos el objeto de la clase FileReader
      const reader: FileReader = new FileReader();

      // prepare callbacks
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          if (
            img.width < this.minResolution ||
            img.height < this.minResolution
          ) {
            this.utilService.alert(
              'GENERICS.ERROR.IMAGE.NOT_VALID_RESOLUTION',
              AlertType.danger
            );
          } else {
            this.base64 = reader.result.toString();
            const image: Image = {
              id: this.imageId,
              image: this.base64,
              changed: true,
              type: 'Gallery'
            };
            this.onConverted.emit(image);
          }
        };
        img.src = reader.result.toString();
      };

      // Start read file
      reader.readAsDataURL(file);
    } else {
      this.utilService.alert(
        'GENERICS.ERROR.IMAGE.NOT_VALID_FORMAT',
        AlertType.danger
      );
    }
  }

  remove() {
    this.base64 = null;
    this.inputImage.nativeElement.value = null;
    const image: Image = {
      id: this.imageId,
      image: this.base64,
      changed: true
    };
    this.onConverted.emit(image);
  }

  add(img: Image) {
    this.imageId = img.id;
    this.base64 = img.image;
  }
}
