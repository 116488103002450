export interface Image {
  id: number;
  image: string;
  changed?: boolean;
  type?: string;
  main?: boolean;
}

export class CreateUpdateImage {
  constructor(
    public originId: number = 0,
    public fileName: string,
    public image: string = '',
    public type: string = 'Gallery',
    public main: boolean = false,
    public enable: boolean = true
  ) {}
}
