import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BalanceModel } from '../models/balance.model';
import { PaginatedResponse } from '../models/paginated.model';
import { BookingResponse } from '../models/response/booking-response.model';
import { WorkerData } from '../models/worker.model';
import { ApiService } from './api.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {
  private readonly uri = 'worker';

  constructor(private api: ApiService, private utilService: UtilService) {}

  getWorkerServicePaginated(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false,
    search = '',
    filter: 'Active' | 'Inactive' | 'All' = 'All'
  ): Observable<PaginatedResponse> {
    return this.api.get(`${this.uri}/service`, {
      currentPage,
      pageSize,
      sortBy,
      sortDesc,
      search,
      filter
    });
  }
  getWorkerLocationPaginated(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false
  ): Observable<PaginatedResponse> {
    const params = this.api.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    return this.api.get(`${this.uri}/location`, params);
  }
  getWorkerBookingPaginated(
    currentPage = 0,
    pageSize = 5,
    sortBy: string = null,
    sortDesc = false
  ): Observable<PaginatedResponse> {
    const params = this.api.buildPaginatedHttpParams(
      currentPage,
      pageSize,
      sortBy,
      sortDesc
    );
    // return this.api.get(`${this.uri}/location`, params);
    return this.getMocBooking();
  }

  getWorkerBalancePaginated(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false,
    search = '',
    status: '' | 'succeeded' | 'canceled' | 'All' = 'All'
  ): Observable<PaginatedResponse> {
    if (status === 'All' || status === undefined) {
      status = '';
    }

    return this.api.get(`${this.uri}/balance`, {
      currentPage,
      pageSize,
      sortBy,
      sortDesc,
      search,
      status
    });
  }
  getWorkerTotalBalance(): Observable<BalanceModel> {
    return this.api.get(`${this.uri}/totalBalance`);
  }

  getLoggedWorkerData() {
    return this.api.get(`${this.uri}`);
  }
  create(workerData: WorkerData) {
    return this.api.post(`${this.uri}`, workerData);
  }

  update(id: number, data: WorkerData) {
    return this.api.put(`${this.uri}/${id}`, data);
  }
  delete(workerId: Number) {
    return this.api.delete(`${this.uri}/${workerId}`);
  }

  getBookings(from: string, to: string): Observable<BookingResponse> {
    return this.api.get(`${this.uri}/booking`, { from, to });
  }

  getMocBooking(): Observable<PaginatedResponse> {
    const data = [
      {
        date: '10/04/2022',
        title: 'Traditional Flamenco show in a historic cave'
      },
      {
        date: '10/04/2022',
        title: 'Basic tour of Madrid'
      },
      {
        date: '10/04/2022',
        title: 'Wine tasting near Plaza Mayor'
      },
      {
        date: '10/04/2022',
        title: 'History and gastronomy in Seville'
      },
      {
        date: '10/04/2022',
        title: 'Tapas cooking in a secret private dining room'
      }
    ];
    const mocBookingObject: PaginatedResponse = {
      count: data.length,
      data: data
    };
    return of(mocBookingObject);
  }
}
