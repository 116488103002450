import { UtilService } from '../../services/util.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase'
})
export class KebabCasePipe implements PipeTransform {
  constructor(private utilService: UtilService) {}

  transform(value: any): any {
    return this.utilService.toKebabCase(value);
  }
}
