import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import {
  DatatableColumns,
  DatatableSettings
} from '../../../../models/datatable.model';
import { TableColumnDirective } from '../../../directives/table-column.directive';

@Component({
  selector: 'app-custom-datatable',
  templateUrl: './custom-datatable.component.html',
  styleUrls: ['./custom-datatable.component.scss']
})
export class CustomDatatableComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() totalRecords: number;
  @Input() columns: DatatableColumns[] = [];
  @Input() settings: DatatableSettings;

  @Output() loadData = new EventEmitter<any>();
  @Output() selectedRow = new EventEmitter<any>();
  @Output() selectedRows = new EventEmitter<any>();
  @Output() buttonClick = new EventEmitter<any>();

  @ContentChildren(TableColumnDirective)
  templates: QueryList<TableColumnDirective>;

  public openFilters: boolean;

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.loadTemplates();
  }

  loadTemplates() {
    this.columns.forEach((column) => {
      column.template = this.templates.find(
        (template) => template.columnName === column.field
      )?.template;
    });
  }

  onLazyLoad(event) {
    const pageSize = event?.rows;
    const currentPage = event?.first / pageSize;
    const orderBy = event?.sortField;
    const desc = event?.sortOrder == -1;

    return this.loadData.emit({
      pageSize,
      currentPage,
      orderBy,
      desc,
      filters: event?.filters
    });
  }

  onButtonClick(action: string, row: any, event: any) {
    event.stopPropagation();
    this.buttonClick.emit({ action, row });
  }

  toggleRow(row) {
    if (this.settings.selectRows != 'multi') {
      this.data
        .filter((x) => x._isSelected && x !== row)
        .forEach((x) => (x._isSelected = false));
    }

    if (this.settings.selectRows) {
      row._isSelected = !row._isSelected;
    }

    const selectedRows = this.data.filter((x) => x._isSelected);
    this.selectedRow.emit(row);
    this.selectedRows.emit(selectedRows);
  }
}
