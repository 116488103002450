import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from './../../../material.module';
import { CustomSpinnerComponent } from './custom-spinner.component';
export { CustomSpinnerComponent } from './custom-spinner.component';
@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [CustomSpinnerComponent],
  exports: [CustomSpinnerComponent]
})
export class CustomSpinnerModule {}
