<div class="c-dialog-add-schedule">
  <div mat-dialog-content>
    <div
      class="d-flex flex-row justify-content-between align-items-baseline mb-20px"
    >
      <h2 mat-dialog-title class="text-uppercase mb-0 c-dialog-add-key__title">
        {{ buttonText }}
      </h2>

      <div mat-dialog-actions>
        <button
          mat-button
          mat-dialog-close
          class="c-dialog-add-key__button-close"
        >
          <span class="fas fa-times"></span>
        </button>
      </div>
    </div>

    <div class="d-flex mb-20px">
      <form [formGroup]="formTimes">
        <!-- Days -->
        <div class="col-12 row d-flex justify-content-center align-items-end">
          <h2>
            {{ 'SERVICE.CALENDAR.DAYS' | translate }}
            <span class="text-danger" *ngIf="dayWeekValid"> *</span>
          </h2>
          <div class="pb-20px d-flex flex-row">
            <label
              class="d-flex flex-row align-items-center me-3"
              *ngFor="let day of daysHead; let i = index"
            >
              <input
                class="mr-1"
                type="checkbox"
                formArrayName="weekdays"
                [value]="day.id"
                [attr.disabled]="day.checked"
                [checked]="day.checked"
                (change)="onCheckboxChange($event.target)"
              />
              {{ day.translate | translate }}
            </label>
          </div>
        </div>

        <!-- Hours -->
        <div class="col-12 row d-flex justify-content-center align-items-end">
          <div class="col-5">
            <h2>
              {{ 'SERVICE.CALENDAR.STARTTIME' | translate }}
              <span class="text-danger" *ngIf="startTimeValid"> *</span>
            </h2>
            <ngb-timepicker
              formControlName="startTime"
              [spinners]="false"
            ></ngb-timepicker>
          </div>
          <p class="col-1">-</p>
          <div class="col-6">
            <h2>
              {{ 'SERVICE.CALENDAR.ENDTIME' | translate }}
              <span class="text-danger" *ngIf="endTimeValid"> *</span>
            </h2>
            <ngb-timepicker
              formControlName="endTime"
              [spinners]="false"
            ></ngb-timepicker>
          </div>
        </div>

        <!-- Date Range -->
        <div class="mt-4">
          <h2>{{ 'SERVICE.CALENDAR.DATERANGE' | translate }}</h2>
          <mat-form-field appearance="fill">
            <mat-date-range-input
              [min]="minDate"
              [rangePicker]="picker"
              [disabled]="data?.startDate"
            >
              <input
                readOnly
                class="mainInputDate"
                matStartDate
                formControlName="fromDate"
                placeholder="Start date"
              />
              <input
                readOnly
                class="mainInputDate"
                matEndDate
                formControlName="toDate"
                placeholder="End date"
                (dateChange)="changeDatePicker($event)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker> </mat-date-range-picker>
          </mat-form-field>
        </div>
      </form>
    </div>

    <div mat-dialog-actions class="c-dialog-add-key__actions-bottom">
      <button
        class="btn btn-primary--active"
        mat-button
        [mat-dialog-close]="formTimes"
        [disabled]="!groupValidator"
      >
        {{ this.buttonText }}
      </button>
    </div>
  </div>
</div>
