<div class="mapSimple">
  <div class="agmMap">
    <agm-map
      [latitude]="selectedMapPoint.location.latitude"
      [longitude]="selectedMapPoint.location.longitude"
      [zoom]="
        selectedMapPoint.location.zoom ? selectedMapPoint.location.zoom : 8
      "
      [zoomControl]="true"
    >
      <agm-marker-cluster
        imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m"
      >
        <agm-marker
          *ngFor="let m of listOfmarkers; let i = index"
          [latitude]="m.location.latitude"
          [longitude]="m.location.longitude"
          [markerDraggable]="false"
        >
          <agm-info-window>
            <strong>{{ m.address }}</strong>
          </agm-info-window>
        </agm-marker>

        <agm-circle
          *ngIf="startPoint?.location"
          [latitude]="startPoint?.location.latitude"
          [longitude]="startPoint?.location.longitude"
          [radius]="radius"
          [fillColor]="'red'"
          [circleDraggable]="false"
          [editable]="false"
        >
        </agm-circle>
      </agm-marker-cluster>
    </agm-map>
  </div>
</div>
