<app-custom-datatable
  [data]="data"
  [totalRecords]="totalRecords"
  [columns]="cols"
  [settings]="settings"
  (loadData)="loadData($event)"
>
  <ng-template table-column="timestamp" let-value="value">
    {{ value | date: 'dd/MM/yyyy' }}
  </ng-template>

  <ng-template table-column="totalValue" let-value="value">
    {{ value / 100 | currency: envCurrency:'symbol' }}
  </ng-template>
  <ng-template table-column="taxValue" let-value="value">
    {{ value / 100 | currency: envCurrency:'symbol' }}
  </ng-template>
  <ng-template table-column="providerFee" let-value="value">
    {{ value / 100 | currency: envCurrency:'symbol' }}
  </ng-template>
  <ng-template table-column="status" let-value="value">
    <span *ngIf="value === 'succeeded'" class="c-payments__succeeded">{{
      'PAYMENTS.SUCCEEDED' | translate
    }}</span>
    <span *ngIf="value === 'canceled'" class="c-payments__expired">{{
      'PAYMENTS.EXPIRED' | translate
    }}</span>
    <span
      *ngIf="value !== 'succeeded' && value !== 'canceled'"
      class="c-payments__pending"
      >{{ 'GENERICS.PENDING' | translate }}</span
    >
  </ng-template>
</app-custom-datatable>
