import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-custom-simple-map',
  templateUrl: './custom-simple-map.component.html',
  styleUrls: ['./custom-simple-map.component.scss']
})
export class CustomSimpleMapComponent implements OnInit {
  @Input() selectedMapPoint: {
    location: {
      type: string;
      latitude: string;
      longitude: string;
      zoom: number;
    };
  };
  @Input() listOfmarkers: {
    address: string;
    location: {
      latitude: string;
      longitude: string;
    };
    type: string;
  }[];
  @Input() radius;

  @Output() selectedBranch = new EventEmitter<any>();
  public iconMap = {
    url: '/assets/img/icon-location-map.svg',
    scaledSize: { height: 30, width: 30 }
  };
  previous;
  desactiveControls = false;
  public startPoint;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.desactiveControls = event.target.innerWidth <= 414 ? true : false;
  }

  constructor() {}

  ngOnInit(): void {
    if (window.innerWidth <= 414) {
      this.desactiveControls = true;
    }
    this.startPoint = this.listOfmarkers.filter((a) => a.type === 'AtHome')[0];
    this.listOfmarkers = this.listOfmarkers.filter((a) => a.type !== 'AtHome');
  }

  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  protected selectMarkerBranch(infoWindow, gm, marker) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();

    this.selectedBranch.emit(marker);
  }
}
