import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { CategorieService } from 'src/app/dashboard/services/categorie.service';
import { OfferService } from 'src/app/dashboard/services/offer.service';
import SwiperCore, {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  SwiperOptions
} from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-offers-carousel',
  templateUrl: './offers-carousel.component.html',
  styleUrls: ['./offers-carousel.component.scss']
})
export class OffersCarouselComponent implements OnInit, OnChanges {
  @Input() relatedOfferData;
  @Input() categoryInfo;
  @Input() offerTypeFilter;
  @Input() offersType: string;

  public scrollMove;
  public scrollLeft = false;
  public scrollRight = true;
  public premium = false;
  public client: string;
  public imageWidth;
  public imageHeight;
  public config: SwiperOptions;

  public offerInCategories = {
    count: 0,
    data: []
  };

  public isLoadingOffers = true;
  constructor(
    private readonly router: Router,
    private categories: CategorieService,
    private offer: OfferService
  ) {}

  ngOnInit(): void {
    this.config = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      navigation: true,
      scrollbar: { draggable: true }
    };
    // get client for css
    this.client = `?client=${localStorage.getItem('client')}`;

    this.premium = this.offersType === 'premium' ? true : false;
    switch (this.offersType) {
      case 'premium':
        this.getOffersPremium();
        this.imageHeight = '221';
        this.imageWidth = '221';
        break;
      case 'related':
        if (this.relatedOfferData) {
          this.fillRelativeOffersData();
          this.isLoadingOffers = false;
          this.imageHeight = '221';
          this.imageWidth = '221';
        }
        break;
      case 'normal':
        this.getOfferByCategory();
        this.imageHeight = '221';
        this.imageWidth = '221';
        break;
      default:
        this.getOfferByCategory();
        this.imageHeight = '221';
        this.imageWidth = '221';
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.offerTypeFilter) {
      this.offersType === 'premium'
        ? this.getOffersPremium()
        : this.getOfferByCategory();
    }
    if (changes.relatedOfferData) {
      this.fillRelativeOffersData();
    }
    if (changes.offersType) {
      this.premium = this.offersType === 'premuim' ? true : false;
    }
  }

  fillRelativeOffersData() {
    this.offerInCategories.count = this.relatedOfferData.data.length;
    this.offerInCategories.data = this.relatedOfferData.data;
  }

  getOfferByCategory() {
    this.isLoadingOffers = true;
    this.categories
      .getOffersByCategory(
        0,
        0,
        null,
        false,
        this.categoryInfo.id,
        this.offerTypeFilter
      )
      .subscribe(
        (result) => {
          if (result) {
            this.isLoadingOffers = false;
            this.offerInCategories.data = result.data;
            this.offerInCategories.count = result.count;
          }
        },
        (error) => {
          this.setObjectsToNull(error);
        }
      );
  }

  getOffersPremium() {
    this.isLoadingOffers = true;
    this.offer
      .getOffersPremium(0, 0, 'weight', false, this.offerTypeFilter)
      .subscribe(
        (result) => {
          if (result) {
            this.isLoadingOffers = false;
            this.offerInCategories.data = result.data;
            this.offerInCategories.count = result.count;
          }
        },
        (error) => {
          this.setObjectsToNull(error);
        }
      );
  }

  setObjectsToNull(error) {
    this.isLoadingOffers = false;
    this.offerInCategories = null;
    console.log(error);
  }

  goToDetailOffer(offer) {
    const titleMerchant = offer.merchant.tradingName
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-');
    const titleOffer = offer.title
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('-');
    const url = `/webcliente/offer/${offer.id}-${titleMerchant}-${titleOffer}${this.client}`;
    this.router.navigateByUrl(url);
  }

  //swiperjs
  onSwiper([swiper]) {}
  onSlideChange() {}
}
