<app-custom-spinner *ngIf="!addressList"></app-custom-spinner>
<div class="containerBranch" *ngIf="addressList">
  <div class="headerBranch">
    <div class="captionStyle">Change Branch</div>
    <span (click)="closeDialog()"><i class="fas fa-times iconClose"></i></span>
  </div>
  <div class="bodyBranch">
    <div class="listBranch">
      <div class="selectOrder"></div>
      <div class="containeraddress">
        <div
          class="address"
          *ngFor="
            let address of addressList
              | paginate: { itemsPerPage: items, currentPage: page };
            let i = index
          "
          [ngClass]="{ checkedaddress: classSelectedaddress === i }"
        >
          <div class="buttonaddress">
            <input
              type="radio"
              name="address"
              [checked]="address.checked"
              id="i"
              [value]="address"
              (change)="selectedaddress(address, i)"
            />
          </div>
          <div class="containerInfo">
            <div class="name">
              {{ address.name }}
            </div>
            <div class="addressData">
              <div class="street">
                {{ address.address }}
              </div>
              <div class="distance">{{ address.distanceKm }} km</div>
            </div>
          </div>
        </div>
      </div>
      <div class="containerPage">
        <pagination-controls
          (pageChange)="page = $event"
          [autoHide]="responsive"
        ></pagination-controls>
      </div>
    </div>

    <div class="mapBranch">
      <app-custom-map
        [selectedMapPoint]="selectedAddress"
        [addressList]="addressList"
        [mapWidth]="_mapWidth"
        [mapHeight]="_mapHeight"
        #mapDialog
        (selectedBranch)="getSelectedBranch($event)"
      ></app-custom-map>
      <button
        class="saveSelectedBranch"
        *ngIf="!responsive"
        (click)="changeSelectedBranch()"
      >
        SAVE SELECTED BRANCH
      </button>
    </div>
    <button
      class="saveSelectedBranch"
      *ngIf="responsive"
      (click)="changeSelectedBranch()"
    >
      SAVE SELECTED BRANCH
    </button>
  </div>
</div>
