import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { paymentDetails } from './../models/payment.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private readonly uri = 'payment';

  constructor(private apiService: ApiService) {}

  startPayment(
    customerName,
    customerEmail,
    address,
    locality,
    postalCode,
    items: { id: number; priceId: number; quantity: number }[]
  ) {
    return this.apiService.post(`${this.uri}/start`, {
      customerName,
      customerEmail,
      address,
      locality,
      postalCode,
      items,
      successUrl: `${window.location.origin}/webcliente/payment/result?session={CHECKOUT_SESSION_ID}&status=success`,
      cancelUrl: `${window.location.origin}/webcliente/payment/result?session={CHECKOUT_SESSION_ID}&status=cancel`
    });
  }

  getSession(id: string, idBookin?: string): Observable<paymentDetails> {
    return this.apiService.post(`${this.uri}/checkout`, {
      id: id,
      booking: { id: idBookin }
    });
  }

  getTotalPayments(id: number) {
    return {
      revenueValue: 10000,
      pendingValue: 5025
    };
  }

  getPayments(id: number) {
    const data = [];
    for (let i = 0; i < id; ++i) {
      data.push({
        service: 'Servicio #' + Math.random(),
        price: Math.floor(Math.random() * 10000) / 100,
        date: Date.now(),
        customerEmail: 'a@b.c',
        customerName: 'Paco Perez',
        status: Math.random() > 0.5 ? 'succeeded' : 'pending'
      });
    }
    return { count: 100, data };
  }
}
