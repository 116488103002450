import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {
  pipes = {
    date: new DatePipe('es')
  };
  pattern = {
    date: 'dd/MM/yyyy hh:mm:ss'
  };

  constructor() {}

  transform(value: string, key: string): any {
    const dPipe: PipeTransform = this.pipes[key];
    if (dPipe) return dPipe.transform(value, this.pattern[key]);
    return value;
  }
}
