import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateUpdateImage } from '../models/image.model';
import { User } from './../models/user.model';
import { ApiService } from './api.service';
import { ImageService } from './image.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly uri = 'user';

  constructor(private api: ApiService, private imageService: ImageService) {}

  getUserData(): Observable<User> {
    return this.api.get(`${this.uri}`);
  }

  update(data: any) {
    return this.api.put(`${this.uri}`, data);
  }

  sendUpdateImage(userData: any) {
    const requestCreateImage: CreateUpdateImage = new CreateUpdateImage(
      userData.id,
      userData.userName,
      userData.image
    );
    return this.imageService.create(`${this.uri}/image`, requestCreateImage);
  }
  deleteImage(imageId: Number) {
    return this.api.delete(`${this.uri}/image/${imageId}`);
  }
}
