import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[table-column]'
})
export class TableColumnDirective {
  @Input('table-column') columnName: string;

  constructor(public readonly template: TemplateRef<any>) {}
}
