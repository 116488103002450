<div id="loginForm" class="m-3">
  <h2>{{ 'LOGIN.LOGIN' | translate }}</h2>
  <div id="loginForm" *ngIf="!forgetPasswd">
    <form
      (click)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
      *ngIf="!loading"
      [formGroup]="formLogin"
      (ngSubmit)="login()"
    >
      <div class="mb-4">
        <input
          class="c-login__input form-control form-control-lg"
          formControlName="email"
          type="email"
          [placeholder]="'REGISTER.EMAIL' | translate"
        />
        <div
          class="c-login__error"
          *ngIf="
            formLogin.get('email').hasError('email') &&
            formLogin.get('email').touched
          "
        >
          {{ 'REGISTER.ERROR.EMAIL_NOT_VALID' | translate }}
        </div>
        <div
          class="c-login__error"
          *ngIf="
            formLogin.get('email').hasError('required') &&
            (formLogin.get('email').touched || formLogin.get('password').dirty)
          "
        >
          {{ 'REGISTER.ERROR.EMAIL_REQUIRED' | translate }}
        </div>
      </div>
      <div class="mb-4">
        <div class="input-group">
          <input
            formControlName="password"
            type="password"
            #passwd
            class="c-login__input form-control form-control-lg"
            [placeholder]="'REGISTER.PASSWORD' | translate"
          />
          <span
            class="c-login__lock d-flex justify-content-center align-items-center"
            (click)="changeLock()"
          >
            <span *ngIf="iconBoolean">
              <i
                class="mt-1 fas fa-fw fa-eye-slash c-login__lock__icon"
                aria-hidden="true"
              ></i>
            </span>
            <span *ngIf="!iconBoolean">
              <i
                class="mt-1 fas fa-fw fa-eye c-login__lock__icon"
                aria-hidden="true"
              ></i>
            </span>
          </span>
        </div>
        <div
          class="c-login__error"
          *ngIf="
            formLogin.get('password').hasError('required') &&
            formLogin.get('password').touched
          "
        >
          {{ 'REGISTER.ERROR.PASSWORD_REQUIRED' | translate }}
        </div>
        <small>
          <a
            class="c-login__forget"
            (click)="$event.stopPropagation(); forgetPasswrd()"
            >{{ 'LOGIN.FORGET' | translate }}</a
          >
        </small>
        <br />
        <small class="mt-1 c-login__error">{{ msgLogin }}</small>
      </div>
      <div
        class="c-login__listButtons mt-2 d-flex justify-content-center align-items-center"
      >
        <button
          class="btn btn-primary--active"
          type="submit"
          [disabled]="!formLogin.valid"
        >
          {{ 'LOGIN.LOGIN' | translate }}
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="forgetPasswd">
    <form (click)="$event.stopPropagation()" [formGroup]="formForgetPass">
      <div class="c-reset__content" *ngIf="!toggleReset">
        <div
          class="c-reset__content__redirectToLogin"
          (click)="$event.stopPropagation(); forgetPasswrd()"
        >
          <span><i class="fas fa-arrow-left iconBack"></i></span>
          <span class="textBack" style="cursor: pointer"> Back</span>
        </div>
        <div>
          <h1 style="font-weight: bold">
            {{ 'LOGIN.RESETPASSWORD' | translate }}
          </h1>
          <p>{{ 'LOGIN.EMAILSASSOCIATED' | translate }}</p>
        </div>
        <div
          style="margin-top: 30px"
          class="d-flex justify-content-center align-item-center"
        >
          <input
            type="email"
            class="c-reset__content__inputMail"
            [placeholder]="'LOGIN.EMAIL' | translate"
            formControlName="email"
          />
          <div
            class="c-login__error"
            *ngIf="
              formForgetPass.get('email').hasError('email') &&
              formForgetPass.get('email').touched
            "
          >
            {{ 'REGISTER.ERROR.EMAIL_NOT_VALID' | translate }}
          </div>
          <div
            class="c-login__error"
            *ngIf="
              formForgetPass.get('email').hasError('required') &&
              formForgetPass.get('email').touched
            "
          >
            {{ 'REGISTER.ERROR.EMAIL_REQUIRED' | translate }}
          </div>
        </div>
        <div
          style="margin-top: 30px"
          class="d-flex justify-content-center align-item-center"
        >
          <button
            class="btn btn-primary--active"
            (click)="sendMailToUser()"
            [disabled]="!formForgetPass.valid"
          >
            {{ 'LOGIN.SEND' | translate }}
          </button>
        </div>
      </div>
      <div
        class="c-reset__content"
        style="text-align: center"
        *ngIf="toggleReset"
      >
        <span class="iconMail"><i class="fas fa-envelope-open-text"></i></span>
        <div class="titleMail">
          {{ 'LOGIN.CHECKEMAIL' | translate }}
        </div>
        <p>{{ 'LOGIN.SENDEMAILPASSWORDRECOVER' | translate }}</p>
        <button
          class="btn btn-primary-notdefault--active"
          (click)="goToLoginForm()"
        >
          Ok
        </button>
      </div>
    </form>
  </div>
  <div class="mt-1 d-flex justify-content-center align-item-center">
    <button
      *ngIf="!forgetPasswd"
      class="btn btn-primary-notdefault--active"
      (click)="goToUserSingUp()"
    >
      {{ 'NAVBAR.SIGNUP' | translate }}
    </button>
  </div>
</div>
