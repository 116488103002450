import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { Login } from '../models/login.model';
import { Tokens } from '../models/tokens.model';
import { environment } from './../../../environments/environment';
import { ApiService } from './api.service';
interface httpResponseCode {
  _httpResponseCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _loggedUser: string;
  private readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient, private api: ApiService) {}

  get url(): string {
    return `${environment.ApiUrl}`;
  }

  login(data: Login): Observable<any> {
    return this.http
      .post<any>(`${this.url}/login`, data, {
        headers: this.httpOptions.headers,
        observe: 'response' as 'body',
        responseType: 'json'
      })
      .pipe(
        tap((response) => {
          this.doLoginUser(response.body);
        }),
        mapTo(true),
        catchError((error) => {
          return of(error.error.message);
        })
      );
  }
  logout() {
    return this.http.post<any>(`${this.url}/logout`, {}).pipe(
      mapTo(true),
      catchError((error) => {
        return of(false);
      })
    );
  }
  register(data: any): Observable<any> {
    return this.http
      .post<Login>(`${this.url}/signUp`, data, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  resetPassword(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post(
        `${this.url}/requestPasswordReset`,
        { email: email },
        { headers, responseType: 'text' }
      )
      .pipe(catchError(this.errorHandler));
  }

  changePassword(password: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http
      .post(
        `${this.url}/requestPasswordReset`,
        { password: password },
        { headers, responseType: 'text' }
      )
      .pipe(catchError(this.errorHandler));
  }

  refreshToken() {
    return this.http
      .post<httpResponseCode>(
        `${this.url}/refreshToken`,
        {},
        { observe: 'response' }
      )
      .pipe(map((todo) => ({ _httpResponseCode: todo.status })));
  }
  isLoggedIn() {
    return !!this.getId();
  }
  getId() {
    return localStorage.getItem('userIdRole');
  }

  passwordReset(password: string, token: string) {
    return this.api.post(`passwordReset/${token}`, { password });
  }

  validateInvitation(password: string, token: string) {
    return this.api.post(`validateInvitation/${token}`, { password });
  }

  validateAccount(token: string) {
    return this.api.get(`validateEmail/${token}`);
  }

  private doLoginUser(tokens: Tokens) {
    localStorage.setItem(
      'userIdRole',
      JSON.stringify({
        id: tokens.id,
        name: tokens.name,
        surname: tokens.surname,
        email: tokens.email,
        image: tokens.image,
        isCustomer: tokens.isCustomer,
        isWorker: tokens.isWorker
      })
    );
  }

  protected errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${
        error?.error?.status || error.status
      }\nMessage: ${error?.error?.message || error.message}`;
    }
    return throwError(errorMessage);
  }
}
