import { Component, OnInit } from '@angular/core';
import { WorkerService } from 'src/app/dashboard/services/worker.service';
import { environment } from 'src/environments/environment';
import {
  DatatableColumns,
  DatatableSettings
} from '../../../../models/datatable.model';

@Component({
  selector: 'app-datatable-worker-payments',
  templateUrl: './datatable-worker-payments.component.html',
  styleUrls: ['./datatable-worker-payments.component.scss']
})
export class DatatableWorkerPaymentsComponent implements OnInit {
  public data: any;
  public totalRecords: number;
  public cols: DatatableColumns[];
  public settings: DatatableSettings;
  public envCurrency;

  constructor(private workerService: WorkerService) {}

  ngOnInit(): void {
    this.envCurrency = environment.appCurrency;
    this.cols = [
      { display: 'PAYMENTS.DATE', field: 'timestamp' },
      { display: 'SERVICE.SERVICES', field: 'service' },
      { display: 'GENERICS.STATUS', field: 'status' },
      { display: 'SERVICE.EMAIL', field: 'customerEmail' },
      { display: 'PAYMENTS.TOTAL', field: 'totalValue' },
      { display: 'PAYMENTS.TAX', field: 'taxValue' },
      { display: 'PAYMENTS.PLATFROM_FEE', field: 'providerFee' }
    ];

    this.settings = {
      rows: 10,
      search: true,
      paginator: true,
      defaultSort: 'timestamp',
      filters: [
        {
          display: 'GENERICS.STATUS',
          field: 'active',
          options: ['All', 'succeeded', 'canceled']
        }
      ]
    };
  }

  async loadData(event) {
    const response = await this.workerService
      .getWorkerBalancePaginated(
        event?.pageSize,
        event?.currentPage,
        event?.orderBy,
        event?.desc,
        event?.filters?.search?.value,
        event?.filters?.active?.value
      )
      .toPromise();
    this.totalRecords = response.count;
    this.data = response.data;
  }
}
