<div class="c-dialog-add-exception">
  <div mat-dialog-content>
    <div
      class="d-flex flex-row justify-content-between align-items-baseline mb-20px"
    >
      <h2 mat-dialog-title class="text-uppercase mb-0 c-dialog-add-key__title">
        {{ buttonText }}
      </h2>

      <div mat-dialog-actions>
        <button
          mat-button
          mat-dialog-close
          class="c-dialog-add-key__button-close"
        >
          <span class="fas fa-times"></span>
        </button>
      </div>
    </div>

    <div class="d-flex mb-20px">
      <form [formGroup]="formException">
        <div id="availableOnOfButton">
          <div class="form-group row mb-3">
            <div class="o-switch">
              <input
                formControlName="exceptionType"
                class="checkbox success sr-only"
                id="exceptionType"
                type="checkbox"
                checked
              />
              <label
                for="exceptionType"
                class="toggle rounded form-control-label"
                aria-hidden="true"
                style="width: 145px"
              >
                <span class="on">{{
                  'SERVICE.CALENDAR.AVAILABLE' | translate
                }}</span>
                <span class="off">{{
                  'SERVICE.CALENDAR.UNAVAILABLE' | translate
                }}</span>
              </label>
            </div>
          </div>
        </div>
        <div>
          <h2>{{ 'GENERICS.DATE' | translate }}</h2>
          <mat-form-field appearance="fill">
            <mat-date-range-input
              [min]="minDate"
              [rangePicker]="picker"
              [disabled]="data?.startDate"
            >
              <input
                readOnly
                class="mainInputDate"
                matStartDate
                formControlName="startDate"
                placeholder="Start date"
              />
              <input
                readOnly
                class="mainInputDate"
                matEndDate
                formControlName="endDate"
                placeholder="End date"
                (dateChange)="changeDatePicker($event)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker> </mat-date-range-picker>
          </mat-form-field>
        </div>
        <div
          id="times"
          class="col-12 row d-flex justify-content-center align-items-end"
        >
          <div class="col-5">
            <h2>
              {{ 'SERVICE.CALENDAR.STARTTIME' | translate }}
              <span class="text-danger" *ngIf="startTimeValid"> *</span>
            </h2>
            <ngb-timepicker
              formControlName="startTime"
              [spinners]="false"
            ></ngb-timepicker>
          </div>
          <p class="col-1">-</p>
          <div class="col-6">
            <h2>
              {{ 'SERVICE.CALENDAR.ENDTIME' | translate }}
              <span class="text-danger" *ngIf="endTimeValid"> *</span>
            </h2>
            <ngb-timepicker
              formControlName="endTime"
              [spinners]="false"
            ></ngb-timepicker>
          </div>
        </div>
      </form>
    </div>

    <div mat-dialog-actions class="c-dialog-add-key__actions-bottom">
      <button
        class="btn btn-primary--active"
        mat-button
        [mat-dialog-close]="formException"
        [disabled]="!groupValidator"
      >
        {{ this.buttonText }}
      </button>
    </div>
  </div>
</div>
