<div class=" d-flex flex-row justify-content-between align-items-baseline mb-20px">
  <h2 mat-dialog-title class="text-uppercase p-0 mb-0">
    Offers
  </h2> 
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="button-close">
      <span class="fas fa-times"></span>
    </button>
  </div>
</div>
<app-custom-spinner *ngIf="!defaultCoords" ></app-custom-spinner>
<app-custom-map *ngIf="defaultCoords" [selectedMapPoint]="defaultCoords" [addressList]="markers" [homePage]="true" 
[mapWidth]="_mapWidth" [mapHeight]="_mapHeigth" (selectedBranch)="getSelectedBranch($event)"></app-custom-map>

<div class="container-adress mt-2" *ngIf="selectedAddress && responsive">
  <div class="name-adress"> <a [href]="urlOffer">{{selectedAddress?.code}}</a></div>
  <div class="adress"><span>{{selectedAddress?.address}}</span>, <span>{{selectedAddress?.city}}</span></div>
  <div class="distance ">{{selectedAddress?.name}} {{selectedAddress?.distanceKm}} KM</div>
</div>

  
