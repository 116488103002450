import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import {
  AppEvents,
  EventBusService
} from '../../../../services/event-bus.service';
import { DialogLogInComponent } from '../../dialogs/dialog-logIn/dialog-logIn.component';

@Component({
  selector: 'app-form-logIn-shared',
  templateUrl: './form-logIn-shared.component.html',
  styleUrls: ['./form-logIn-shared.component.scss']
})
export class FormLogInSharedComponent implements OnInit {
  @Input() parentComponent;
  //login
  loading = false;
  formLogin: FormGroup;
  formForgetPass: FormGroup;
  iconBoolean: boolean;
  forgetPasswd: boolean;
  currentUserData;
  msgLogin = '';
  @ViewChild('passwd') passwd: ElementRef;
  public toggleReset = false;
  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private authService: AuthService,
    private router: Router,
    private bus: EventBusService,
    @Optional() private parentDilogRef?: MatDialogRef<DialogLogInComponent>
  ) {
    this.iconBoolean = true;
    this.forgetPasswd = false;
  }

  ngOnInit(): void {
    this.loadForm();
  }

  loadForm() {
    this.formLogin = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }
  loadForgetPassForm() {
    this.formForgetPass = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  login() {
    if (this.formLogin.valid) {
      this.loading = true;
      const data = {
        email: this.formLogin.get('email').value.toLowerCase(),
        password: this.formLogin.get('password').value
      };
      this.authService.login(data).subscribe(async (response: any) => {
        if (response === true) {
          this.msgLogin = '';
          this.currentUserData = JSON.parse(localStorage.getItem('userIdRole'));
          this.bus.emit(AppEvents.update.profile, this.currentUserData);
          if (this.parentComponent !== 'navbar') {
            if (this.parentDilogRef) this.parentDilogRef.close();
          } else {
            this.redirec();
          }
        } else {
          this.loading = false;
          this.msgLogin = response;
          console.error(response);
        }
      });
    }
  }

  redirec() {
    if (this.currentUserData.isWorker) {
      this.router.navigateByUrl('/webcliente/worker-home');
    } else if (this.currentUserData.isCustomer) {
      this.router.navigateByUrl('/webcliente/customer-home');
    } else {
      this.router.navigateByUrl('/webcliente/home');
    }
  }
  changeLock() {
    if (this.iconBoolean) {
      this.renderer.setAttribute(this.passwd?.nativeElement, 'type', 'text');
      this.iconBoolean = !this.iconBoolean;
    } else {
      this.renderer.setAttribute(this.passwd.nativeElement, 'type', 'password');
      this.iconBoolean = !this.iconBoolean;
    }
  }

  forgetPasswrd() {
    this.forgetPasswd = !this.forgetPasswd;
    this.loadForgetPassForm();
  }
  sendMailToUser() {
    const email = this.formForgetPass.get('email').value;
    this.toggleReset = true;
    this.authService.resetPassword(email).subscribe(
      (response: any) => {
        if (response) {
          this.toggleReset = true;
        } else {
          console.error(response);
          this.router.navigateByUrl('webcliente/home');
        }
      },
      (error: any) => {
        console.log('error', error);
        this.router.navigateByUrl('webcliente/home');
      }
    );
  }
  goToUserSingUp() {
    this.router.navigateByUrl('/webcliente/client-register');
  }
  goToLoginForm() {
    this.toggleReset = false;
    this.forgetPasswd = false;
  }
}
