<div class="component">
  <div @tooltip class="d-flex align-items-center justify-content-start">
    <img
      *ngFor="let lang of content"
      [src]="'assets/img/flags/' + lang.split('-')[0] + '.png'"
      [alt]="lang"
      width="25"
      height="25"
      class="rounded-circle flagImg ml-2"
      style="border: 1px solid white"
    />
  </div>
</div>
